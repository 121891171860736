import { FC, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@redwoodjs/web';
import { Spinner } from 'src/components/Spinner';
import {
  GET_BRANDED_DOCUMENT_CONFIG_QUERY,
  GET_BRAND_ASSETS_QUERY,
  GET_CV_CONFIG_QUERY,
  GENERATE_CV_PDF_QUERY,
  GENERATE_BRANDED_DOCUMENT_QUERY,
  GET_COVERSHEET_CONFIG_QUERY,
  GENERATE_COVERSHEET_PDF_QUERY,
} from 'src/graphql/queries';
import { PdfViewer } from 'src/components/PdfViewer';
import { CvTabForm } from './BrandingTabs/CvTabForm';
import { AssetsTabForm } from './BrandingTabs/AssetsTabForm';
import { Link, Tabs } from 'src/components';
import { navigate, routes } from '@redwoodjs/router';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import {
  GetBrandedDocumentConfig,
  GetBrandAssets,
  GetCvConfig,
  GetBrandedDocumentConfigVariables,
  GetBrandAssetsVariables,
  GetCvConfigVariables,
  GenerateCvPdfQuery,
  GenerateCvPdfQueryVariables,
  GenerateBrandedDocumentQuery,
  GenerateBrandedDocumentQueryVariables,
  CvLayoutInput,
  GenerateCoversheetPdfQuery,
  GetCoversheetConfigVariables,
  GenerateCoversheetPdfQueryVariables,
  GetCoversheetConfig,
  CandidateCoversheetLayoutInput,
} from 'types/graphql';
import { usePageClasses } from 'src/hooks';
import { DocumentsTabForm } from './BrandingTabs/DocumentsTabForm';
import { DUMMY_COVERSHEET_PDF_DATA, DUMMY_CV_DATA, DUMMY_PDF_PREVIEW_DATA } from '../../constants';
import { HeaderTab } from './constants';
import { stripTypenames } from 'src/lib/object';
import { CoversheetTabForm } from './BrandingTabs/CoversheetTabForm';

type TabOptions = ReadonlyArray<'Assets' | 'Documents' | 'CV' | 'Cover Sheet'>;

export const BrandedDocumentSettings: FC = () => {
  usePageClasses('bg-pageGray');
  const TABS: TabOptions = ['Assets', 'Documents', 'CV', 'Cover Sheet'] as const;
  const [selectedTab, setSelectedTab] = useState<'Assets' | 'Documents' | 'CV' | 'Cover Sheet'>(
    'Assets'
  );
  const [selectedHeaderTab, setSelectedHeaderTab] = useState<HeaderTab>('Logo');
  const [pdfDetails, setPdfDetails] = useState<{
    templateId: string | null;
    layout: unknown | null;
  }>({
    templateId: null,
    layout: null,
  });
  const [coversheetPdfDetails, setCoversheetPdfDetails] = useState<{
    templateId: string | null;
    layout: unknown | null;
  }>({
    templateId: null,
    layout: null,
  });
  const {
    data: configData,
    loading: configLoading,
    previousData: configPreviousData,
  } = useQuery<GetBrandedDocumentConfig, GetBrandedDocumentConfigVariables>(
    GET_BRANDED_DOCUMENT_CONFIG_QUERY
  );
  const {
    data: brandAssetsData,
    loading: brandAssetsLoading,
    previousData: brandAssetsPreviousData,
  } = useQuery<GetBrandAssets, GetBrandAssetsVariables>(GET_BRAND_ASSETS_QUERY);
  const {
    data: cvConfigData,
    loading: cvConfigLoading,
    previousData: cvConfigPreviousData,
  } = useQuery<GetCvConfig, GetCvConfigVariables>(GET_CV_CONFIG_QUERY, {
    onCompleted: (d) =>
      setPdfDetails({
        layout: d.getCvConfig?.defaultLayout ?? d.getCvConfig?.CvTemplate?.defaultLayout,
        templateId: d.getCvConfig?.CvTemplate?.id ?? null,
      }),
  });

  const {
    data: coversheetConfigData,
    loading: coversheetConfigLoading,
    previousData: coversheetConfigPreviousData,
  } = useQuery<GetCoversheetConfig, GetCoversheetConfigVariables>(GET_COVERSHEET_CONFIG_QUERY, {
    onCompleted: (d) => {
      const layout =
        d.getCoversheetConfig?.defaultLayout ??
        d.getCoversheetConfig?.coversheetTemplate?.defaultLayout;
      setCoversheetPdfDetails({
        layout,
        templateId: d.getCoversheetConfig?.coversheetTemplate?.id ?? null,
      });
    },
  });

  const {
    data: generateBrandedDocumentData,
    loading: brandedPdfLoading,
    refetch: refetchBrandedPdf,
  } = useQuery<GenerateBrandedDocumentQuery, GenerateBrandedDocumentQueryVariables>(
    GENERATE_BRANDED_DOCUMENT_QUERY
  );

  const {
    data: pdfData,
    loading: pdfLoading,
    refetch,
  } = useQuery<GenerateCvPdfQuery, GenerateCvPdfQueryVariables>(GENERATE_CV_PDF_QUERY, {
    variables: {
      input: {
        ...DUMMY_CV_DATA,
        layout: stripTypenames(pdfDetails.layout as CvLayoutInput),
      },
      templateId: pdfDetails.templateId,
    },
    skip: selectedTab !== 'CV' || !pdfDetails.templateId,
  });

  const {
    data: coversheetPdfData,
    loading: coversheetPdfLoading,
    refetch: refetchCoversheetPdf,
  } = useQuery<GenerateCoversheetPdfQuery, GenerateCoversheetPdfQueryVariables>(
    GENERATE_COVERSHEET_PDF_QUERY,
    {
      variables: {
        input: {
          ...DUMMY_COVERSHEET_PDF_DATA,
          layout: (coversheetPdfDetails.layout
            ? stripTypenames(coversheetPdfDetails?.layout as Record<string, unknown>)
            : undefined) as CandidateCoversheetLayoutInput,
        },
        templateId: coversheetPdfDetails.templateId,
      },
      skip: selectedTab !== 'Cover Sheet' || !coversheetPdfDetails.templateId,
    }
  );

  const configDataResult = configData || configPreviousData;
  const brandAssetsResult = brandAssetsData || brandAssetsPreviousData;
  const cvConfigDataResult = cvConfigData || cvConfigPreviousData;
  const coversheetConfigDataResult = coversheetConfigData || coversheetConfigPreviousData;

  useEffect(() => {
    refetchBrandedPdf({
      input: {
        markup: selectedTab === 'Documents' ? DUMMY_PDF_PREVIEW_DATA : undefined,
        showBorder: selectedTab === 'Assets',
        headerType:
          selectedTab === 'Assets'
            ? selectedHeaderTab === 'Logo'
              ? 'LOGO'
              : 'LETTERHEAD'
            : configDataResult?.getBrandedDocumentConfig?.headerStyle,
        showFooter:
          selectedTab === 'Documents'
            ? configDataResult?.getBrandedDocumentConfig?.showFooter
            : selectedHeaderTab === 'Logo',
      },
    });
  }, [configDataResult, brandAssetsResult, selectedTab, selectedHeaderTab]);

  useEffect(() => {
    refetch();
  }, [cvConfigDataResult, pdfDetails.templateId, brandAssetsResult]);

  useEffect(() => {
    refetchCoversheetPdf();
  }, [coversheetConfigData, pdfDetails.templateId, brandAssetsResult]);

  const pdfBlobUrl = useMemo(
    () =>
      pdfData?.generateCvPdf?.pdfContent
        ? `data:application/pdf;base64,${pdfData?.generateCvPdf.pdfContent}`
        : undefined,
    [pdfData]
  );

  const coversheetPdfBlobUrl = useMemo(
    () =>
      coversheetPdfData?.generateCoversheetPdf?.pdfContent
        ? `data:application/pdf;base64,${coversheetPdfData?.generateCoversheetPdf?.pdfContent}`
        : undefined,
    [coversheetPdfData]
  );

  const brandedPdfBlobUrl = useMemo(() => {
    if (generateBrandedDocumentData?.generateBrandedDocument?.pdfContent) {
      return `data:application/pdf;base64,${generateBrandedDocumentData.generateBrandedDocument.pdfContent}`;
    }
    return null;
  }, [generateBrandedDocumentData]);

  const handleTabChange = (tab: 'Assets' | 'Documents' | 'CV' | 'Cover Sheet') => {
    setSelectedTab(tab);
  };

  const isBrandAssetsLoading = brandAssetsLoading && !brandAssetsPreviousData;
  const isConfigLoading = configLoading && !configPreviousData;
  const isCvConfigLoading = cvConfigLoading && !cvConfigPreviousData;
  const isCoversheetConfigLoading = coversheetConfigLoading && !coversheetConfigPreviousData;

  if (isBrandAssetsLoading || isConfigLoading || isCvConfigLoading || isCoversheetConfigLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex h-screen overflow-hidden ">
      <div className="flex basis-5/12 flex-col gap-y-3 overflow-auto bg-pageGray px-12 py-5">
        <Link
          size="medium"
          onClick={() => navigate(routes.settingsOrganisation())}
          LeftIcon={ArrowLeftIcon}
          className="text-sm font-normal text-text-veryDark"
        >
          Back to settings
        </Link>

        <div className="flex flex-col justify-center gap-y-1">
          <h1 className="text-2xl font-bold text-text-dark">Branding</h1>
          <p className="text-sm font-normal text-text-medium">
            Customise AdScribe documents to match your company brand. Changes will be saved
            automatically.
          </p>
        </div>

        <Tabs
          options={TABS}
          betaOption="Cover Sheet"
          selected={selectedTab}
          setSelected={handleTabChange}
        />

        <div className="flex max-w-2xl flex-col justify-center gap-y-1 pt-5">
          {selectedTab === 'Assets' && (
            <AssetsTabForm
              headerTabControl={{ selectedHeaderTab, setSelectedHeaderTab }}
              brandAssets={brandAssetsResult?.getBrandAssets}
            />
          )}
          {selectedTab === 'Documents' && (
            <DocumentsTabForm configData={configDataResult?.getBrandedDocumentConfig} />
          )}
          {selectedTab === 'CV' && (
            <CvTabForm
              cvConfigData={cvConfigDataResult?.getCvConfig}
              brandAssetsData={brandAssetsResult?.getBrandAssets}
              onTemplateSelect={(templateId, layout) => {
                setPdfDetails({
                  templateId,
                  layout,
                });
              }}
              selectedTemplateId={pdfDetails.templateId ?? ''}
            />
          )}
          {selectedTab === 'Cover Sheet' && (
            <CoversheetTabForm
              coversheetConfigData={coversheetConfigDataResult?.getCoversheetConfig}
              brandAssetsData={brandAssetsResult?.getBrandAssets}
              onTemplateSelect={(templateId, layout) => {
                setCoversheetPdfDetails({
                  templateId,
                  layout,
                });
              }}
              selectedTemplateId={coversheetPdfDetails.templateId ?? ''}
            />
          )}
        </div>
      </div>
      <div className="flex basis-7/12 flex-col items-center bg-white">
        <div className="flex flex-1 flex-col items-center p-4">
          {selectedTab === 'CV' ? (
            <PdfViewer fileUrl={pdfBlobUrl ?? ''} loading={pdfLoading} />
          ) : selectedTab === 'Cover Sheet' ? (
            <PdfViewer fileUrl={coversheetPdfBlobUrl ?? ''} loading={coversheetPdfLoading} />
          ) : selectedTab === 'Documents' ? (
            <PdfViewer fileUrl={brandedPdfBlobUrl ?? ''} loading={brandedPdfLoading} />
          ) : (
            <>{brandedPdfBlobUrl && <PdfViewer fileUrl={brandedPdfBlobUrl} />}</>
          )}
        </div>
      </div>
    </div>
  );
};
