import { CoversheetInput, CvOutput } from 'types/graphql';

export const CV_FIELD_PRESENTATIONAL_NAMES: Record<keyof Omit<CvOutput, '__typename'>, string> = {
  achievements: 'Achievements',
  certifications: 'Certifications',
  education: 'Education',
  experience: 'Work Experience',
  interests: 'Interests',
  languages: 'Languages',
  name: 'Name',
  profileSummary: 'Profile Summary',
  skills: 'Skills',
  jobTitle: 'Job Title',
  location: 'Location',
  availability: 'Availability',
  refId: 'Reference ID',
  rightToWork: 'Right to Work',
  currentSalary: 'Current Salary',
  desiredSalary: 'Expected Salary',
};

export const COVERSHEET_FIELD_PRESENTATIONAL_NAMES: Record<
  keyof Omit<CoversheetInput, '__typename' | 'layout'>,
  string
> = {
  name: 'Name',
  jobTitle: 'Current Role',
  profileIntroduction: 'Comments',
  sectors: 'Sector Experience',
  desiredJobTitle: 'Desired Job Title',
  desiredSalary: 'Expected Salary',
  location: 'Location',
  noticePeriod: 'Availability',
  refId: 'Reference ID',
  rightToWork: 'Right to Work',
  consultantDetails: 'Consultant Details',
  relevantSkills: 'Key Skills',
  qualifications: 'Qualifications',
};
