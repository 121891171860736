import _ from 'lodash';

/**
 *  Deeply (recursively) compare two objects
 */
export function deepEqual(a: unknown, b: unknown): boolean {
  return _.isEqual(a, b);
}

// Recursively strip __typename from an object
type StripTypenames<T> = T extends Array<infer U>
  ? Array<StripTypenames<U>>
  : T extends { __typename: any }
  ? Omit<{ [K in keyof T]: StripTypenames<T[K]> }, '__typename'>
  : T extends object
  ? { [K in keyof T]: StripTypenames<T[K]> }
  : T;

// Recursively strip __typename from an object
export function stripTypenames<T extends Array<Record<string, unknown>> | Record<string, unknown>>(
  obj: T
): StripTypenames<T> {
  if (Array.isArray(obj)) {
    return obj.map(stripTypenames) as StripTypenames<T>;
  }

  if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce<StripTypenames<T>>((acc, key) => {
      if (key === '__typename') {
        return acc;
      }
      return {
        ...acc,
        [key]: stripTypenames(obj[key] as Record<string, unknown>),
      };
    }, {} as StripTypenames<T>);
  }

  return obj;
}
