import { CV_LAYOUT_FIELDS_FRAGMENT } from '../fragments';

export const UPDATE_CV_CONFIG_MUTATION = gql`
  ${CV_LAYOUT_FIELDS_FRAGMENT}
  mutation UpdateCvConfig($input: UpdateCvConfigInput!) {
    updateCvConfig(input: $input) {
      id
      CvTemplate {
        id
        defaultLayout {
          ...CvLayoutFields
        }
      }
      accentColor
      headerType
      logoAlignment
      font
      fontColor
      capitaliseHeading
      boldHeading
      showBorder
      showFooter
      borderColor
      marginSize
      fontScale
      headingFont
      headingColor
      defaultLayout {
        ...CvLayoutFields
      }
    }
  }
`;
