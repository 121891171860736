import { Bars3BottomLeftIcon, Bars3BottomRightIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { serializeDate } from 'src/lib/dateTime';

export const footerFontOptions = [
  { label: 'Roboto', value: 'Roboto' },
  { label: 'Libre Baskerville', value: 'Libre Baskerville' },
  { label: 'Roboto Mono', value: 'Roboto Mono' },
];

export const alignmentOptions = [
  { icon: Bars3BottomLeftIcon, value: 'left' },
  { icon: Bars3Icon, value: 'center' },
  { icon: Bars3BottomRightIcon, value: 'right' },
];

export const marginOptions = [
  { label: 'Narrow', value: '40' },
  { label: 'Standard', value: '72' },
  { label: 'Wide', value: '112' },
];

export const logoSizeOptions = [
  { label: 'Small', value: '64' },
  { label: 'Medium', value: '124' },
  { label: 'Large', value: '224' },
];

export const tableBorderOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

// htmldocument then innerhtml it
export const DUMMY_PDF_PREVIEW_DATA = `<h1>Join Our Team as a Recruitment Consultant at AdScribe</h1><p>Are you passionate about matching the right talent with the right opportunity? Do you have a knack for understanding people's strengths and how they fit into an organization? AdScribe, a pioneering recruitment AI company, is looking for a dynamic Recruitment Consultant to join our team.</p><ol><li>Identify and attract top talent using AdScribe’s innovative AI-powered recruitment tools.</li><li>Develop and maintain strong relationships with clients and candidates, ensuring a smooth recruitment process from start to finish.</li></ol><p>As a Recruitment Consultant at AdScribe, you will leverage our cutting-edge technology to streamline the hiring process, providing exceptional service to both clients and candidates. Your responsibilities will include:</p><ul><li>Conducting thorough interviews and assessments to understand candidate skills, experience, and career goals.</li><li>Collaborating with clients to understand their hiring needs and providing tailored recruitment solutions.</li></ul><p>If you are enthusiastic about the intersection of technology and recruitment, and are ready to make a significant impact in the industry, we want to hear from you!</p>`;

export const fontOptions = [
  { label: 'Roboto', value: 'Roboto' },
  { label: 'Libre Baskerville', value: 'Libre Baskerville' },
  { label: 'Roboto Mono', value: 'Roboto Mono' },
];

export const fontScaleOptions = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
];

export const horizontalMarginOptions = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
];
export const DUMMY_CV_DATA = {
  name: 'John Doe',
  jobTitle: 'Senior Legal Counsel',
  location: 'New York, NY',
  availability: 'Full-time',
  refId: '333-333-333',
  rightToWork: 'Green Card',
  currentSalary: '$100,000',
  desiredSalary: '$120,000',
  profileSummary:
    '<p>Experienced legal professional with over 10 years of expertise in corporate law, contract negotiation, and compliance. Adept at providing strategic legal advice and ensuring regulatory adherence.</p>',
  experience: [
    {
      startDate: serializeDate(new Date('2015-01-01')),
      endDate: serializeDate(new Date('2023-01-01')),
      title: 'Senior Legal Counsel',
      company: 'ABC Corporation',
      location: 'New York, NY',
      description:
        '<p>Provided legal counsel on corporate matters, including mergers and acquisitions, contract negotiations, and compliance issues.</p><ul><li>Led a team of junior lawyers in high-stakes negotiations.</li><li>Ensured compliance with federal and state regulations.</li><li>Drafted and reviewed complex legal documents.</li></ul>',
    },
    {
      startDate: serializeDate(new Date('2010-01-01')),
      endDate: serializeDate(new Date('2014-12-31')),
      title: 'Legal Associate',
      company: 'XYZ Law Firm',
      location: 'New York, NY',
      description:
        '<p>Assisted senior solicitors in litigation and corporate law cases.</p><ul><li>Conducted legal research and prepared case briefs.</li><li>Represented clients in court under supervision.</li><li>Drafted legal documents and contracts.</li></ul>',
    },
  ],
  education: [
    {
      startDate: serializeDate(new Date('2006-09-01')),
      endDate: serializeDate(new Date('2009-06-01')),
      title: 'Juris Doctor (JD)',
      institution: 'Harvard Law School',
      location: 'Cambridge, MA',
      description:
        '<p>Graduated with honours, focusing on corporate law and international business transactions.</p><ul><li>Member of the Harvard Law Review.</li><li>Completed internships with top law firms.</li></ul>',
    },
    {
      startDate: serializeDate(new Date('2002-09-01')),
      endDate: serializeDate(new Date('2006-06-01')),
      title: 'Bachelor of Arts in Political Science',
      institution: 'University of California, Berkeley',
      location: 'Berkeley, CA',
      description:
        '<p>Graduated summa cum laude with a major in Political Science and a minor in Economics.</p><ul><li>President of the Debate Club.</li><li>Completed a thesis on international trade law.</li></ul>',
    },
  ],
  skills: [
    {
      title: 'Corporate Law',
      description: 'Expertise in corporate governance and compliance.',
    },
    {
      title: 'Contract Negotiation',
      description: 'Skilled negotiator with a track record of successful deals.',
    },
    {
      title: 'Compliance',
      description: 'Ensuring regulatory adherence and risk management.',
    },
    { title: 'Litigation', description: 'Experience in civil and commercial litigation.' },
    {
      title: 'Legal Research',
      description: 'Proficient in legal research and analysis.',
    },
  ],
  achievements: [
    {
      description:
        '<p>Recognised as a top legal counsel by the New York Bar Association in 2020.</p>',
    },
    {
      description: '<p>Successfully negotiated a $500 million merger deal for ABC Corporation.</p>',
    },
  ],
  certifications: [
    {
      title: 'Certified Compliance & Ethics Professional (CCEP)',
      description: '<p>Compliance Certification Board (CCB)</p>',
    },
  ],
  interests: [{ description: 'Reading' }, { description: 'Travelling' }, { description: 'Chess' }],
  languages: [
    { language: 'English', level: 5 },
    { language: 'Spanish', level: 4 },
  ],
};

export const DUMMY_COVERSHEET_PDF_DATA = {
  name: 'Sarah Johnson',
  jobTitle: 'Senior Corporate Counsel',
  profileIntroduction:
    '<p>Sarah is an exceptional senior corporate counsel with over 15 years of experience in corporate law, compliance, and contract negotiation. She has a proven track record of successfully handling complex legal matters for Fortune 500 companies.</p><ul><li>Led legal teams of up to 20 attorneys across multiple jurisdictions</li><li>Reduced legal spend by 30% through process optimization and vendor management</li><li>Completed over 50 M&A transactions with total value exceeding $2B</li><li>Implemented comprehensive compliance programs for multinational organizations</li><li>Regular speaker at industry conferences on corporate governance</li></ul>',
  desiredJobTitle: 'General Counsel',
  sectors: 'Technology, Finance, Healthcare',
  desiredSalary: '$200,000 - $250,000',
  noticePeriod: '3 months',
  location: 'New York, NY',
  refId: 'CAN-2023-0472',
  consultantDetails: '<p>John Doe</p><p>john.doe@example.com</p><p>123-456-7890</p>',
  relevantSkills: [
    { title: 'Corporate Law & Governance' },
    { title: 'Contract Negotiation & Review' },
    { title: 'Regulatory Compliance' },
    { title: 'Risk Management' },
    { title: 'Mergers & Acquisitions' },
  ],
  qualifications: [
    { description: 'Certified Compliance & Ethics Professional (CCEP)' },
    { description: 'Certified in Corporate Governance (CCG)' },
    { description: 'Certified in Contract Management (CCM)' },
    { description: 'Certified in Risk Management (CRM)' },
    { description: 'Certified in Mergers & Acquisitions (CMA)' },
  ],
  rightToWork: 'Yes',
};
