import { FC, useEffect, useRef, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useMutation } from '@redwoodjs/web';
import { navigate, routes } from '@redwoodjs/router';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowLeftIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Reorder } from 'framer-motion';
import { UPDATE_STRUCTURED_DOCUMENT_MUTATION } from 'src/graphql/mutations';
import {
  CandidateCoversheet,
  CandidateCoversheetLayoutInput,
  CoversheetInput,
  UpdateStructuredDocument,
  UpdateStructuredDocumentVariables,
} from 'types/graphql';

import {
  Link,
  TextField,
  RichTextField,
  Form,
  DraggableItem,
  Accordion,
  PageTitle,
  Tabs,
} from 'src/components';

import {
  CandidateCoversheetFormValues,
  candidateCoversheetFormSchema,
} from './structuredDocumentFormSchema';
import { useBoolean, useDebounce } from 'src/hooks';
import { DocumentLayoutEditorField } from '../DocumentLayoutEditor';
import { stripTypenames } from 'src/lib/object';

type StructuredCoversheetEditorProps = {
  document: Pick<CandidateCoversheet, 'id' | 'structuredMarkup' | 'title' | 'layout'>;
  candidateId?: string;
};

const TABS = ['Coversheet Details', 'Layout'] as const;
type Tabs = (typeof TABS)[number];

export const CoversheetMarkupEditor: FC<StructuredCoversheetEditorProps> = ({
  document,
  candidateId,
}) => {
  const [tab, setTab] = useState<Tabs>('Coversheet Details');
  const [openAccordions, setOpenAccordions] = useState<{ [key: string]: boolean }>({});
  const { value: isAccordionOpen, setTrue: openAllAccordions } = useBoolean(false);

  const formErrorRef = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const formMethods = useForm<CandidateCoversheetFormValues>({
    resolver: zodResolver(candidateCoversheetFormSchema),
    defaultValues: {
      name: document.structuredMarkup?.name ?? '',
      jobTitle: document.structuredMarkup?.jobTitle ?? '',
      desiredJobTitle: document.structuredMarkup?.desiredJobTitle ?? '',
      location: document.structuredMarkup?.location ?? '',
      profileIntroduction: document.structuredMarkup?.profileIntroduction ?? '',
      noticePeriod: document.structuredMarkup?.noticePeriod ?? '',
      rightToWork: document.structuredMarkup?.rightToWork ?? '',
      sectors: document.structuredMarkup?.sectors ?? '',
      refId: document.structuredMarkup?.refId ?? '',
      consultantDetails: document.structuredMarkup?.consultantDetails ?? '',
      desiredSalary: document.structuredMarkup?.desiredSalary ?? '',
      relevantSkills:
        document.structuredMarkup?.relevantSkills?.map((skill) => ({
          title: skill.title,
        })) ?? [],
      qualifications:
        document.structuredMarkup?.qualifications?.map((qual) => ({
          description: qual.description ?? '',
        })) ?? [],
      layout: document?.layout ? stripTypenames(document.layout) : null,
    },
  });

  const {
    fields: relevantSkillsFields,
    append: appendRelevantSkill,
    remove: removeRelevantSkill,
    replace: replaceRelevantSkills,
  } = useFieldArray({ control: formMethods.control, name: 'relevantSkills', keyName: 'fieldId' });

  const {
    fields: qualificationsFields,
    append: appendQualification,
    remove: removeQualification,
    replace: replaceQualifications,
  } = useFieldArray({
    control: formMethods.control,
    name: 'qualifications',
    keyName: 'fieldId',
  });

  const liveRelevantSkills = formMethods.watch('relevantSkills') ?? [];
  const mergedRelevantSkillsFields = relevantSkillsFields.map((f, i) => ({
    ...f,
    ...liveRelevantSkills?.[i],
  }));

  const liveQualifications = formMethods.watch('qualifications') ?? [];
  const mergedQualificationsFields = qualificationsFields.map((f, i) => ({
    ...f,
    ...liveQualifications?.[i],
  }));

  const watchedFormValues: CandidateCoversheetFormValues = {
    ...formMethods.watch(),
    relevantSkills: mergedRelevantSkillsFields,
    qualifications: mergedQualificationsFields,
  };

  const debouncedValues = useDebounce(watchedFormValues, 500);

  const [updateCoverSheet] = useMutation<
    UpdateStructuredDocument,
    UpdateStructuredDocumentVariables
  >(UPDATE_STRUCTURED_DOCUMENT_MUTATION);

  useEffect(() => {
    const transformedValues: CoversheetInput = {
      ...debouncedValues,
      relevantSkills: debouncedValues.relevantSkills?.map((skill) => ({
        title: skill.title,
      })),
      qualifications: debouncedValues.qualifications?.map((qual) => ({
        description: qual.description ?? '',
      })),
      layout: debouncedValues.layout as CandidateCoversheetLayoutInput,
    };

    updateCoverSheet({
      variables: {
        id: document.id,
        input: {
          candidateCoversheetInput: transformedValues,
        },
      },
    });
  }, [debouncedValues, updateCoverSheet, document.id]);

  useEffect(() => {
    const errors = formMethods.formState.errors;
    if (Object.keys(errors).length > 0) {
      openAllAccordions();
    }
  }, [formMethods.formState.errors]);

  return (
    <div className="flex flex-1 flex-col overflow-hidden pt-2 ">
      <div className="flex flex-col px-4 md:px-16">
        <Link
          size="medium"
          LeftIcon={ArrowLeftIcon}
          onClick={() => navigate(routes.candidate({ candidateId: candidateId ?? '' }))}
        >
          Back to Candidate
        </Link>
        <PageTitle className="pt-3" size="sm" Icon={PencilIcon} text={document.title ?? ''} />
        <p className="pt-1 text-text-medium">
          You are editing a Candidate Cover Sheet. Changes you make will be auto-saved.
        </p>
        <Tabs<Tabs> selected={tab} setSelected={setTab} options={TABS} />
      </div>
      <Form<CandidateCoversheetFormValues>
        className="flex min-h-0 flex-1 flex-col"
        formMethods={formMethods}
      >
        {tab === 'Coversheet Details' && (
          <div className="flex flex-1 flex-col gap-y-4 overflow-auto px-4 py-4 md:px-16">
            {/* Name, Job Title, Desired Job Title, Location, Notice Period */}
            <div className="grid grid-cols-2 gap-4">
              <TextField
                ref={(el) => (formErrorRef.current['name'] = el)}
                label="Name"
                name="name"
                placeholder="John Doe"
              />
              <TextField
                ref={(el) => (formErrorRef.current['refId'] = el)}
                label="Reference ID"
                name="refId"
                placeholder="CRM ID"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <TextField
                ref={(el) => (formErrorRef.current['jobTitle'] = el)}
                label="Current Role"
                name="jobTitle"
                placeholder="Senior Software Engineer - Microsoft"
              />
              <TextField
                ref={(el) => (formErrorRef.current['desiredJobTitle'] = el)}
                label="Desired Job Title"
                name="desiredJobTitle"
                placeholder="Team Lead"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <TextField
                ref={(el) => (formErrorRef.current['desiredSalary'] = el)}
                label="Expected Salary"
                name="desiredSalary"
                placeholder="£50k + commission"
              />
              <TextField
                ref={(el) => (formErrorRef.current['location'] = el)}
                label="Location"
                name="location"
                placeholder="San Francisco, CA"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <TextField
                ref={(el) => (formErrorRef.current['noticePeriod'] = el)}
                label="Availability"
                name="noticePeriod"
                placeholder="2 weeks"
              />
              <TextField
                ref={(el) => (formErrorRef.current['rightToWork'] = el)}
                label="Right to Work"
                name="rightToWork"
                placeholder="Yes"
              />
            </div>

            <div>
              <TextField
                ref={(el) => (formErrorRef.current['sectors'] = el)}
                label="Sectors"
                name="sectors"
                placeholder="Software Development, Cloud, DevOps"
              />
            </div>

            {/* Profile */}
            <div className="flex flex-col space-y-2 pt-2">
              <RichTextField
                label="Comments"
                name="profileIntroduction"
                placeholder="Experienced software engineer with a strong background in full-stack development..."
                className="h-[556px]"
              />
            </div>

            {/* Qualifications */}
            <div className="flex flex-col space-y-2 pt-2">
              <h2 className="text-lg font-semibold">Qualifications</h2>
              <Reorder.Group
                values={mergedQualificationsFields}
                onReorder={replaceQualifications}
                axis="y"
                className="flex flex-col gap-y-4"
              >
                {mergedQualificationsFields.map((field, index) => {
                  const fieldKey = `qualifications.${index}`;

                  return (
                    <DraggableItem
                      key={field.fieldId}
                      field={field}
                      index={index}
                      removeHandler={removeQualification}
                    >
                      <Accordion
                        headline={field.description || 'Untitled'}
                        isOpen={openAccordions[fieldKey] || isAccordionOpen}
                        onToggle={() =>
                          setOpenAccordions((prev) => ({
                            ...prev,
                            [fieldKey]: !prev[fieldKey],
                          }))
                        }
                      >
                        <TextField
                          ref={(el) => (formErrorRef.current['qualifications'] = el)}
                          label="Qualification"
                          name={`qualifications.${index}.description`}
                        />
                      </Accordion>
                    </DraggableItem>
                  );
                })}
              </Reorder.Group>
              <Link
                size="medium"
                LeftIcon={PlusIcon}
                className="text-generate-medium"
                iconClassName="text-generate-medium"
                onClick={() => {
                  const newIndex = qualificationsFields.length;
                  const newFieldKey = `qualifications.${newIndex}`;
                  appendQualification({
                    description: '',
                  });
                  setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                }}
              >
                Add Qualification
              </Link>
            </div>

            <div className="flex flex-col space-y-2 pt-2">
              <h2 className="text-lg font-semibold">Key Skills</h2>
              <Reorder.Group
                values={mergedRelevantSkillsFields}
                onReorder={replaceRelevantSkills}
                axis="y"
                className="flex flex-col gap-y-4"
              >
                {mergedRelevantSkillsFields.map((field, index) => {
                  const fieldKey = `relevantSkills.${index}`;
                  return (
                    <DraggableItem
                      key={field.fieldId}
                      field={field}
                      index={index}
                      removeHandler={removeRelevantSkill}
                    >
                      <Accordion
                        headline={field.title || 'Untitled'}
                        isOpen={openAccordions[fieldKey] || isAccordionOpen}
                        onToggle={() =>
                          setOpenAccordions((prev) => ({
                            ...prev,
                            [fieldKey]: !prev[fieldKey],
                          }))
                        }
                      >
                        <TextField
                          ref={(el) => (formErrorRef.current['relevantSkills'] = el)}
                          label="Skill"
                          name={`relevantSkills.${index}.title`}
                          required
                          className="h-[42px]"
                        />
                      </Accordion>
                    </DraggableItem>
                  );
                })}
              </Reorder.Group>
              <Link
                size="medium"
                LeftIcon={PlusIcon}
                className="text-generate-medium"
                iconClassName="text-generate-medium"
                onClick={() => {
                  const newIndex = relevantSkillsFields.length;
                  const newFieldKey = `relevantSkills.${newIndex}`;
                  appendRelevantSkill({
                    title: '',
                  });
                  setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                }}
              >
                Add Skill
              </Link>
            </div>
            <RichTextField
              label="Consultant Details"
              name="consultantDetails"
              placeholder="John Doe"
            />
          </div>
        )}
        {tab === 'Layout' && (
          <div className="flex flex-1 flex-col gap-y-3 overflow-auto px-4 py-4 md:px-16">
            <h2 className="text-lg font-semibold">Layout</h2>
            <p className="text-sm text-text-medium">
              Drag the cards to re-arrange sections in your Cover Sheet.
            </p>
            <DocumentLayoutEditorField name="layout" documentType="CandidateCoversheet" />
          </div>
        )}
      </Form>
    </div>
  );
};
