import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  ArrowDownTrayIcon,
  ArrowPathIcon,
  BriefcaseIcon,
  DocumentDuplicateIcon,
  UserIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { useApolloClient } from '@apollo/client';
import { MetaTags, useMutation, useQuery } from '@redwoodjs/web';
import { PageTitle } from '../../components/PageTitle';
import {
  CAMPAIGN_STATUS,
  CreateCandidateCampaign,
  CreateCandidateCampaignVariables,
  DOCUMENT_TYPE,
  DuplicateCandidateMutation,
  DuplicateCandidateMutationVariables,
  GetCandidateAndDocuments,
  GetCandidateAndDocumentsVariables,
  GetDocumentQuery,
  GetDocumentQueryVariables,
  RegenerateCandidateCampaign,
  RegenerateCandidateCampaignVariables,
  UpdateCandidate,
  UpdateCandidateVariables,
} from 'types/graphql';
import { Spinner } from '../../components/Spinner';
import {
  Button,
  DetailSnippet,
  EntityStatusPill,
  Moonwalk,
  StatusPill,
  Tabs,
} from '../../components';
import { BanknotesIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { DocumentEditor } from '../../components/DocumentEditor';
import {
  useBoolean,
  useDialog,
  usePageClasses,
  useQueryParamSyncedState,
  useSyncQueryParams,
  useTimeout,
  useTrackPageView,
} from '../../hooks';
import { toast } from '@redwoodjs/web/dist/toast';
import { navigate, routes, useParams } from '@redwoodjs/router';
import { DocumentListItem } from '../../components';
import { GET_DOCUMENT_QUERY } from '../../graphql/queries/getDocumentQuery';
import { RegenerateForm } from './RegenerateForm';
import { SubmitHandler } from 'react-hook-form';
import { GET_CANDIDATE_AND_DOCUMENTS_QUERY } from '../../graphql/queries';
import {
  CREATE_CANDIDATE_CAMPAIGN_MUTATION,
  DUPLICATE_CANDIDATE_MUTATION,
  REGENERATE_CANDIDATE_CAMPAIGN_MUTATION,
  UPDATE_CANDIDATE_MUTATION,
} from '../../graphql/mutations';
import { CandidateFormValues } from '../../lib/formSchemas';
import { DropdownButton } from '../../components/DropdownButton';
import { CandidateExportDialog } from 'src/components/ExportDialogs/CandidateExportDialog';
import CampaignLogo from '../../assets/Campaign.svg';
import WandIcon from '../../assets/Wand.svg';
import { notNullish } from 'src/lib/guards';
import { NonOwnerViewDialog, ShareCandidateDialog } from 'src/components/ShareDialogs';
import { hasRequiredAccess } from 'src/lib/accessControl';
import { OtherPermissions } from 'src/components/OtherPermissions';
import { FadeIn } from 'src/components/FadeIn';
import { serializeDate } from 'src/lib/dateTime';

type Props = {
  candidateId: string;
  initial?: string;
  documentType?: DOCUMENT_TYPE;
};

const TABS = ['Documents', 'Candidate Details'] as const;
type Tabs = (typeof TABS)[number];

const CandidatePage = ({ candidateId, initial, documentType }: Props) => {
  useTrackPageView();
  usePageClasses('bg-pageGray');
  const client = useApolloClient();
  const { setQueryParams } = useSyncQueryParams();
  const params = useParams();

  const [fetchPolicy, setFetchPolicy] = useState<'cache-and-network' | 'network-only'>(
    'network-only'
  );

  const initialTab = params.tab || 'Documents';
  const initialDocType = params.docType || 'CandidateIntroduction';

  const { value: tab, setValue: setTab } = useQueryParamSyncedState<Tabs>(
    'tab',
    initialTab as Tabs
  );

  const { value: selectedDocType, setValue: setSelectedDocType } =
    useQueryParamSyncedState<DOCUMENT_TYPE>('docType', initialDocType as DOCUMENT_TYPE);

  const { show, close } = useDialog();

  const {
    value: isRegenerateCampaignButtonVisible,
    setTrue: showRegenerateCampaignButton,
    setFalse: hideRegenerateCampaignButton,
  } = useBoolean(false);

  // Only show the regenerate campaign button for 10 seconds
  useTimeout(
    () => {
      if (isRegenerateCampaignButtonVisible) {
        hideRegenerateCampaignButton();
      }
    },
    isRegenerateCampaignButtonVisible ? 10000 : null
  );

  const {
    error,
    data,
    loading: queryLoading,
    startPolling,
    stopPolling,
    previousData,
  } = useQuery<GetCandidateAndDocuments, GetCandidateAndDocumentsVariables>(
    GET_CANDIDATE_AND_DOCUMENTS_QUERY,
    {
      variables: { candidateId: candidateId },
      fetchPolicy,
      pollInterval: 5000,
      onCompleted: (data) => {
        if (data?.candidate?.campaignStatus === 'COMPLETED') {
          stopPolling();
          setFetchPolicy('cache-and-network');
          // Populate doc cache
          data?.candidate?.documents?.forEach((doc) => {
            client.writeQuery<GetDocumentQuery, GetDocumentQueryVariables>({
              query: GET_DOCUMENT_QUERY,
              variables: { id: doc.id },
              data: {
                __typename: 'Query',
                document: {
                  ...doc,
                  history: [],
                },
              },
            });
          });
          if (initial === 'true') {
            toast.success('Your campaign is ready!');
            /**
             * Get rid of 'initial' param to avoid further toasts when cache updates
             */
            navigate(routes.candidate({ candidateId: candidateId }));
          }
        }
      },
    }
  );

  const documents =
    (data ?? previousData)?.candidate?.documents
      ?.filter((i) => i.isActive)
      .reduce<
        Partial<
          Record<
            DOCUMENT_TYPE,
            Exclude<
              Exclude<GetCandidateAndDocuments['candidate'], null | undefined>['documents'],
              null | undefined
            >[0]
          >
        >
      >((prev, curr) => {
        prev[curr.__typename] = curr;
        return prev;
      }, {}) ?? {};

  const selectedDocument = documents[selectedDocType];
  const hasDocuments = documents && Object.keys(documents).length > 0;

  const myPermission = data?.candidate?.myPermission;

  const isOwner = hasRequiredAccess(myPermission, 'OWNER');

  const TABS: readonly ('Documents' | 'Candidate Details')[] = hasDocuments
    ? ['Documents', 'Candidate Details']
    : ['Candidate Details'];

  const isCandidateDetailsTabSelected = tab === 'Candidate Details';

  useEffect(() => {
    const params: Record<string, string> = { tab };
    if (tab === 'Documents') {
      params.docType = selectedDocType;
    }
    setQueryParams(params);
  }, [tab, selectedDocType, setQueryParams]);

  /* This useEffect determines the initial tab to display based on the presence of active documents.
     If active documents exist and no specific tab is defined in the URL parameters, it sets the tab to 'Documents'.
     Otherwise, it defaults to 'Candidate Details'. This ensures users are directed to relevant content upon page load. */
  useEffect(() => {
    const hasCampaign = !!data?.candidate?.campaignStatus;

    if (!data) return;

    if (hasCampaign) {
      if (!params.tab) {
        setTab('Documents');
      }
    } else {
      setTab('Candidate Details');
    }
  }, [queryLoading, data, params.tab]);

  const [createCandidateCampaign, { loading: createCandidateCampaignLoading }] = useMutation<
    CreateCandidateCampaign,
    CreateCandidateCampaignVariables
  >(CREATE_CANDIDATE_CAMPAIGN_MUTATION);

  const [regenerateCandidateCampaign, { loading: regenerateCampaignLoading }] = useMutation<
    RegenerateCandidateCampaign,
    RegenerateCandidateCampaignVariables
  >(REGENERATE_CANDIDATE_CAMPAIGN_MUTATION);

  const handleCreateCandidateCampaign = async () => {
    const response = await createCandidateCampaign({
      variables: {
        id: candidateId,
      },
    });
    if (response?.data?.createCandidateCampaign.campaignStatus === 'PENDING') {
      startPolling(5000);
    }
  };

  const handleRegenerateCandidateCampaign = async () => {
    const response = await regenerateCandidateCampaign({
      variables: { id: candidateId },
    });

    if (response?.data?.regenerateCandidateCampaign.campaignStatus === 'PENDING') {
      startPolling(5000);
    }
  };

  const handleGenerateOrRegenerateCandidateCampaign = () => {
    if (data?.candidate?.campaignStatus) {
      handleRegenerateCandidateCampaign();
    } else {
      handleCreateCandidateCampaign();
    }
  };

  const campaignCreationSection = (myPermission === 'READ' || myPermission === 'WRITE') && (
    <div className="flex flex-1 flex-col items-center justify-center gap-6 p-4">
      <CampaignLogo />
      <p className="max-w-xl text-center text-2xl font-medium text-text-medium">
        Click below to generate a personalised campaign for this candidate.
      </p>
      <div className="flex px-16 py-2">
        <Button
          className="flex-grow"
          LeftIcon={WandIcon}
          text="Generate"
          size="mega"
          disabled={createCandidateCampaignLoading}
          onClick={handleCreateCandidateCampaign}
        />
      </div>
    </div>
  );

  const [duplicateCandidate] = useMutation<
    DuplicateCandidateMutation,
    DuplicateCandidateMutationVariables
  >(DUPLICATE_CANDIDATE_MUTATION, {
    onCompleted: (data) => {
      toast.success('Candidate created');
      navigate(routes.candidate({ candidateId: data.duplicateCandidate.id }));
    },
  });

  const [updateCandidate, { error: mutationError }] = useMutation<
    UpdateCandidate,
    UpdateCandidateVariables
  >(UPDATE_CANDIDATE_MUTATION, {
    onCompleted: ({ updateCandidate }) => {
      if (updateCandidate?.campaignStatus === 'PENDING') {
        setTab('Documents');
      }
    },
    optimisticResponse({ id, input }) {
      return {
        __typename: 'Mutation',
        updateCandidate: {
          id,
          ...input,
          __typename: 'Candidate',
          campaignStatus: 'COMPLETED',
          documents: [],
        },
      } as UpdateCandidate;
    },
  });

  /**
   * Prevent spinner showing when refetching after cache update
   */
  const loading = queryLoading && !previousData;

  const initialExperienceIds = data?.candidate?.experience?.map((exp) => exp.id);
  const initialEducationIds = data?.candidate?.education?.map((edu) => edu.id);
  const initialSkillIds = data?.candidate?.skills?.map((skill) => skill.id);
  const initialAchievementIds = data?.candidate?.achievements?.map((ach) => ach.id);
  const initialCertificationIds = data?.candidate?.certifications?.map((cert) => cert.id);
  const initialLanguageIds = data?.candidate?.languages?.map((lang) => lang.id);
  const initialInterestIds = data?.candidate?.interests?.map((interest) => interest.id);

  const onUpdateCandidate: SubmitHandler<CandidateFormValues> = async (values) => {
    const formattedExperience = values.experience?.map((exp) => ({
      ...exp,
      startDate: exp.startDate && serializeDate(exp.startDate),
      endDate: exp.endDate && serializeDate(exp.endDate),
    }));
    const formattedEducation = values.education?.map((edu) => ({
      ...edu,
      startDate: edu.startDate && serializeDate(edu.startDate),
      endDate: edu.endDate && serializeDate(edu.endDate),
    }));
    const formattedCertifications = values.certifications?.map((cer) => ({
      ...cer,
    }));
    const formattedSkills = values.skills?.map((skill) => ({
      ...skill,
      description: skill.description ?? '',
    }));

    const formattedLanguages = values.languages?.map((language) => ({
      ...language,
      language: language.language,
      level: language.level,
    }));

    const formattedInterests = values.interests?.map((interest) => ({
      ...interest,
      description: interest.description,
    }));

    const deletedExperienceIds =
      initialExperienceIds?.filter((id) => !values.experience?.some((exp) => exp.id === id)) ?? [];
    const deletedEducationIds =
      initialEducationIds?.filter((id) => !values.education?.some((edu) => edu.id === id)) ?? [];
    const deletedSkillIds =
      initialSkillIds?.filter((id) => !values.skills?.some((skill) => skill.id === id)) ?? [];
    const deletedAchievementIds =
      initialAchievementIds
        ?.filter((id) => !values.achievements?.some((ach) => ach.id === id))
        .filter(notNullish) ?? [];
    const deletedCertificationIds =
      initialCertificationIds?.filter(
        (id) => !values.certifications?.some((cert) => cert.id === id)
      ) ?? [];
    const deletedLanguageIds =
      initialLanguageIds?.filter((id) => !values.languages?.some((lang) => lang.id === id)) ?? [];
    const deletedInterestIds =
      initialInterestIds?.filter(
        (id) => !values.interests?.some((interest) => interest.id === id)
      ) ?? [];

    await updateCandidate({
      variables: {
        id: candidateId,
        input: {
          name: values.name,
          refId: values.refId,
          jobTitle: values.jobTitle,
          desiredJobTitle: values.desiredJobTitle,
          location: values.location,
          availability: values.availability,
          currentSalary: values.currentSalary,
          notes: values.notes,
          desiredSalary: values.desiredSalary,
          experience: formattedExperience,
          education: formattedEducation,
          skills: formattedSkills,
          achievements: values.achievements?.map((achievement) => ({
            id: achievement.id || undefined,
            description: achievement.description,
          })),
          profileSummary: values.profileSummary,
          rightToWork: values.rightToWork,
          languages: formattedLanguages,
          interests: formattedInterests,
          certifications: formattedCertifications,
          deletedExperienceIds,
          deletedEducationIds,
          deletedSkillIds,
          deletedAchievementIds,
          deletedCertificationIds,
          deletedLanguageIds,
          deletedInterestIds,
        },
      },
    });

    toast.success('Changes have been saved');
    showRegenerateCampaignButton();
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    throw error;
  }

  if (data?.candidate?.campaignStatus === 'PENDING') {
    return <Moonwalk text="Crafting your Candidate Introduction..." />;
  }

  if (!data?.candidate) {
    console.error('No candidate found');
    return null;
  }

  const onArchiveCandidate = () => {
    updateCandidate({
      variables: {
        id: candidateId,
        input: {
          status: 'ARCHIVED',
        },
      },
    });
    toast.success('Candidate archived');
  };

  const onUnArchiveCandidate = () => {
    updateCandidate({
      variables: {
        id: candidateId,
        input: {
          status: 'ACTIVE',
        },
      },
    });
    toast.success('Candidate restored');
  };

  const dropdownOptions = [
    isOwner
      ? {
          text: 'Share Candidate',
          Icon: UserPlusIcon,
          onClick: () => {
            show(
              <ShareCandidateDialog
                isOwner={myPermission === 'OWNER'}
                candidateId={data?.candidate?.id ?? ''}
                onClose={close}
                title="Share Candidate Details"
                description="Sharing does not give members access to your campaign or documents, only the candidate details."
              />
            );
          },
        }
      : null,
    isOwner
      ? {
          text: 'Regenerate campaign',
          Icon: ArrowPathIcon,
          onClick: handleRegenerateCandidateCampaign,
        }
      : null,
    {
      text: 'Create a copy',
      Icon: DocumentDuplicateIcon,
      onClick: () => {
        duplicateCandidate({ variables: { id: candidateId } });
      },
    },
    {
      text: 'Export',
      Icon: ArrowDownTrayIcon,
      onClick: () => show(<CandidateExportDialog id={candidateId} />),
    },
    isOwner
      ? data?.candidate?.status === 'ACTIVE'
        ? {
            text: 'Archive',
            onClick: onArchiveCandidate,
            Icon: ArchiveBoxIcon,
          }
        : {
            text: 'Restore',
            onClick: onUnArchiveCandidate,
            Icon: ArchiveBoxXMarkIcon,
          }
      : null,
  ].filter(notNullish);

  return (
    <div className="flex min-h-screen">
      <MetaTags title="Candidate" description={data?.candidate?.name} />

      <div className="flex-grow flex-col overflow-auto pt-4 lg:flex lg:flex-1">
        <div className="flex items-center justify-between px-4 pt-1 lg:px-16">
          <div className="flex flex-col flex-wrap">
            <div className="flex flex-row gap-x-3">
              <PageTitle size="sm" Icon={UserIcon} text={data.candidate.name} />
            </div>
            <div className="flex flex-row flex-wrap gap-x-3">
              {data.candidate.jobTitle && (
                <DetailSnippet Icon={BriefcaseIcon} text={data.candidate.jobTitle} />
              )}
              {data.candidate.desiredSalary && (
                <DetailSnippet Icon={BanknotesIcon} text={data.candidate.desiredSalary} />
              )}
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-3">
            <EntityStatusPill
              status={data.candidate.status}
              campaignStatus={data.candidate.campaignStatus as CAMPAIGN_STATUS}
            />
            <DropdownButton options={dropdownOptions} />
          </div>
        </div>

        <div className="relative pt-4 lg:mx-16">
          <Tabs<Tabs> selected={tab} setSelected={setTab} options={TABS} />
          <FadeIn className="absolute bottom-4 right-0" visible={isCandidateDetailsTabSelected}>
            <OtherPermissions
              permissionsData={data?.candidate?.permissions ?? []}
              onClick={() => {
                isOwner
                  ? show(
                      <ShareCandidateDialog
                        isOwner={isOwner}
                        candidateId={data?.candidate?.id ?? ''}
                        onClose={close}
                        title="Share Candidate Details"
                        description="Sharing does not give members access to your campaign or documents, only the candidate details."
                      />
                    )
                  : show(
                      <NonOwnerViewDialog
                        onClose={close}
                        permissionsData={data?.candidate?.permissions ?? []}
                      />
                    );
              }}
            />
          </FadeIn>
        </div>
        <div className="flex flex-1 basis-5/12 flex-col overflow-hidden">
          {tab === 'Candidate Details' ? (
            !!data.candidate && (
              <RegenerateForm
                hasCampaign={!!data.candidate.campaignStatus}
                candidate={data.candidate}
                onClickRegenerate={onUpdateCandidate}
                error={mutationError}
                myPermission={myPermission}
                isRegenerateCampaignButtonVisible={isRegenerateCampaignButtonVisible}
                onHandleRegenerateCampaign={handleGenerateOrRegenerateCandidateCampaign}
                regenerateCampaignLoading={regenerateCampaignLoading}
              />
            )
          ) : (
            <div className="flex flex-1 flex-col overflow-hidden px-16">
              <div className="flex flex-col gap-y-4 py-6">
                <DocumentListItem
                  display="list"
                  onClick={() => setSelectedDocType('CandidateIntroduction')}
                  selected={selectedDocType === 'CandidateIntroduction'}
                  type="CandidateIntroduction"
                />
                <DocumentListItem
                  display="list"
                  onClick={() => setSelectedDocType('CandidateSnapshot')}
                  selected={selectedDocType === 'CandidateSnapshot'}
                  type="CandidateSnapshot"
                />

                {
                  // only if typename of candidatecv exists in candidate docs
                  data.candidate.documents?.some(
                    (doc) => doc.__typename === 'CandidateCoversheet'
                  ) && (
                    <div className="flex items-center gap-x-4">
                      <div className="w-[300px]">
                        <DocumentListItem
                          display="list"
                          onClick={() => setSelectedDocType('CandidateCoversheet')}
                          selected={selectedDocType === 'CandidateCoversheet'}
                          type="CandidateCoversheet"
                        />
                      </div>
                      <StatusPill text="BETA" size="sm" color="cyan" />
                    </div>
                  )
                }
                {
                  // only if typename of candidatecv exists in candidate docs
                  data.candidate.documents?.some((doc) => doc.__typename === 'CandidateCv') && (
                    <DocumentListItem
                      display="list"
                      onClick={() => setSelectedDocType('CandidateCv')}
                      selected={selectedDocType === 'CandidateCv'}
                      type="CandidateCv"
                    />
                  )
                }
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={'flex basis-7/12 flex-row overflow-y-hidden bg-white'}>
        {!hasDocuments || !selectedDocument
          ? campaignCreationSection
          : selectedDocument && (
              <DocumentEditor
                candidateId={candidateId}
                key={selectedDocument?.id}
                id={selectedDocument?.id}
              />
            )}
      </div>
    </div>
  );
};

export default CandidatePage;
