import { z } from 'zod';

const coversheetLayoutFieldSchema = z.object({
  row: z.number(),
  column: z.number().nullish(),
  fixed: z.boolean().nullish(),
  hidden: z.boolean().nullish(),
});

export const coversheetLayoutSchema = z.object({
  name: coversheetLayoutFieldSchema,
  jobTitle: coversheetLayoutFieldSchema,
  desiredJobTitle: coversheetLayoutFieldSchema,
  desiredSalary: coversheetLayoutFieldSchema,
  noticePeriod: coversheetLayoutFieldSchema,
  location: coversheetLayoutFieldSchema,
  sectors: coversheetLayoutFieldSchema,
  profileIntroduction: coversheetLayoutFieldSchema,
  refId: coversheetLayoutFieldSchema,
  relevantSkills: coversheetLayoutFieldSchema,
  qualifications: coversheetLayoutFieldSchema,
  rightToWork: coversheetLayoutFieldSchema,
  consultantDetails: coversheetLayoutFieldSchema,
});

export const coversheetTemplateFormSchema = z.object({
  templateId: z.string().uuid().nullish(),
  accentColor: z.string().optional(),
  headerType: z.enum(['LOGO', 'LETTERHEAD']).optional(),
  logoAlignment: z.string().optional(),
  font: z.string().optional(),
  fontColor: z.string().optional(),
  headingFont: z.string().optional(),
  headingColor: z.string().optional(),
  fontScale: z.string().optional(),
  marginSize: z.string().optional(),
  capitaliseHeading: z.boolean().optional(),
  boldHeading: z.boolean().optional(),
  showBorder: z.boolean().optional(),
  showFooter: z.boolean().optional(),
  borderColor: z.string().optional(),
  defaultLayout: coversheetLayoutSchema.nullish(),
});

export type CoversheetTemplateFormValues = z.infer<typeof coversheetTemplateFormSchema>;
