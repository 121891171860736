import { gql } from '@apollo/client';

export const CV_LAYOUT_FIELDS_FRAGMENT = gql`
  fragment CvLayoutFields on CvLayout {
    name {
      row
      column
      fixed
      hidden
    }
    jobTitle {
      row
      column
      fixed
      hidden
    }
    profileSummary {
      row
      column
      fixed
      hidden
    }
    experience {
      row
      column
      fixed
      hidden
    }
    education {
      row
      column
      fixed
      hidden
    }
    skills {
      row
      column
      fixed
      hidden
    }
    achievements {
      row
      column
      fixed
      hidden
    }
    certifications {
      row
      column
      fixed
      hidden
    }
    interests {
      row
      column
      fixed
      hidden
    }
    languages {
      row
      column
      fixed
      hidden
    }
    availability {
      row
      column
      fixed
      hidden
    }
    location {
      row
      column
      fixed
      hidden
    }
    refId {
      row
      column
      fixed
      hidden
    }
    rightToWork {
      row
      column
      fixed
      hidden
    }
    currentSalary {
      row
      column
      fixed
      hidden
    }
    desiredSalary {
      row
      column
      fixed
      hidden
    }
  }
`;
