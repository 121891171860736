import { useQuery } from '@redwoodjs/web';
import { usePageClasses } from 'src/hooks';
import { GetMeQuery, GetMeQueryVariables } from 'types/graphql';
import { GET_ME_QUERY } from 'src/graphql/queries';
import { Spinner } from 'src/components/Spinner';
import { FC } from 'react';
import { toast } from '@redwoodjs/web/dist/toast';
import { Tooltip } from 'src/components/Tooltip';
import { MARKING_SITE_URL } from 'src/lib/constants';
import { Button } from 'src/components';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import EarnMoney from 'src/assets/EarnMoney.svg';
const REFERRAL_URL = `${MARKING_SITE_URL}/ai-recruitment-software-demo?referral=`;

const ReferralPage: FC = () => {
  usePageClasses('bg-white');
  const { data, loading, error } = useQuery<GetMeQuery, GetMeQueryVariables>(GET_ME_QUERY, {
    variables: {
      isAdmin: false,
    },
  });

  if (error) {
    throw error;
  }

  if (loading) {
    return <Spinner />;
  }

  const myId = data?.me?.id;
  // Referral is base64 encoded id then sliced to last 12 characters
  const referralCode = btoa(myId ?? '').slice(-12);

  return (
    <div className="flex max-w-[1400px] flex-1 flex-col px-8 pt-6">
      <div className="text-xl font-bold text-text-dark">Refer an Agency</div>
      <div className="border-b border-gray-200 pt-4" />
      <div className="flex-grow flex-col pt-6">
        <p className="text-text-dark">
          Receive <span className="font-bold text-coscribe">£200</span> when an agency you refer
          signs up to AdScribe!
        </p>
        <div className="flex flex-row items-center gap-x-4">
          <EarnMoney height={192} width={192} />
          <div className="flex flex-col gap-y-2">
            <h2 className="font-bold text-text-dark">How it Works</h2>
            <ol className="flex list-decimal flex-col gap-y-2 pl-6 pt-2  text-text-medium">
              <li>
                🔗 Share your unique referral link with an agency you think would benefit from
                AdScribe
              </li>
              <li>🗓️ Encourage them to book a demo using your personalised link</li>
              <li>
                💰 When they become a customer, you&apos;ll receive £200 after their third month!
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center gap-x-4 pt-6">
        <div className="font-medium text-text-dark">Your code:</div>
        <ReferralCode code={referralCode} />
        <div className="pl-4">
          <Button
            variant="primary"
            size="small"
            text="Copy referral link"
            LeftIcon={DocumentDuplicateIcon}
            onClick={() => {
              navigator.clipboard.writeText(REFERRAL_URL + referralCode);
              toast.success('Referral link copied to clipboard');
            }}
          />
        </div>
      </div>
    </div>
  );
};

const ReferralCode = ({ code }: { code: string }) => {
  const onClick = () => {
    navigator.clipboard.writeText(REFERRAL_URL + code);
    toast.success('Referral link copied to clipboard');
  };

  return (
    <Tooltip position="top" minWidth="100px" innerBody={'Copy to clipboard'}>
      <div onClick={onClick} className="text-text-veryDark underline hover:cursor-pointer ">
        {code}
      </div>
    </Tooltip>
  );
};

export default ReferralPage;
