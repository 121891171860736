import { gql } from '@apollo/client';

export const DOCUMENT_PERMISSIONS_FRAGMENT = gql`
  fragment DocumentPermissions on Document {
    ... on JobSocialPost {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on JobAdvert {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on JobSnapshot {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on JobInMail {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on JobEmail {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on JobInterviewQuestions {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on JobBooleanSearch {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on JobReferenceCheck {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on JobInterviewPreparation {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on CandidateSnapshot {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on CompanySnapshot {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on CompanyIntroduction {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on CandidateIntroduction {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on CandidateCv {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on CandidateCoversheet {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on BlogPost {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on ClientProposal {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on BlogPostIdeas {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on MarketingStrategy {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on TestimonialRequest {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
    ... on General {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }

    ... on ClientOutreach {
      id
      permissions {
        __typename
        ... on UserPermission {
          id
          user {
            email
            id
            name
          }
          permission
        }
      }
      myPermission
    }
  }
`;
