import { FC, PropsWithChildren } from 'react';
import {
  GET_API_VERSION,
  GET_LATEST_RELEASE_NOTIFIER_QUERY,
  GET_ME_QUERY,
} from 'src/graphql/queries';
import {
  GetApiVersion,
  GetApiVersionVariables,
  GetLatestReleaseNotifierQuery,
  GetLatestReleaseNotifierQueryVariables,
  GetMeQuery,
  GetMeQueryVariables,
} from 'types/graphql';
import { useMutation, useQuery } from '@redwoodjs/web';
import { useAuth } from '../../auth';
import semver from 'semver';
import { APP_VERSION } from 'src/services/env';
import { motion } from 'framer-motion';
import { Link } from '../Link';
import { ArrowTopRightOnSquareIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { UPDATE_USER_LAST_VIEWED_RELEASE_NOTES_VERSION } from 'src/graphql/mutations/updateUserLastViewedReleaseNotesVersion';
import Mascot from 'src/assets/Mascot.svg';
import { IconButton } from '../IconButton';

export const AppVersionChecker = ({ children }: PropsWithChildren) => {
  const { isAuthenticated, currentUser } = useAuth();

  const {
    data: meData,
    loading: meLoading,
    refetch,
  } = useQuery<GetMeQuery, GetMeQueryVariables>(GET_ME_QUERY, {
    skip: !isAuthenticated,
    variables: {
      isAdmin: currentUser?.role === 'ADMIN',
    },
  });

  const { data: apiVersionData, loading: apiVersionLoading } = useQuery<
    GetApiVersion,
    GetApiVersionVariables
  >(GET_API_VERSION, {
    // Only check for app banner if a user is already logged in
    skip: !isAuthenticated,
    pollInterval: 1000 * 60,
  });

  const { data: latestReleaseNotifierData, loading: latestReleaseNotifierLoading } = useQuery<
    GetLatestReleaseNotifierQuery,
    GetLatestReleaseNotifierQueryVariables
  >(GET_LATEST_RELEASE_NOTIFIER_QUERY, {
    skip: !isAuthenticated,
    pollInterval: 1000 * 60 * 60 * 24,
  });

  const [updateUserLastViewedReleaseNotesVersion] = useMutation(
    UPDATE_USER_LAST_VIEWED_RELEASE_NOTES_VERSION,
    {
      optimisticResponse: {
        updateUserLastViewedReleaseNotesVersion: {
          id: currentUser?.id,
          lastViewedReleaseNotesVersion: latestReleaseNotifierData?.latestReleaseNotifier?.version,
          __typename: 'User',
        },
      },
      onCompleted: () => {
        refetch();
      },
    }
  );

  const loading = meLoading || apiVersionLoading || latestReleaseNotifierLoading;

  const apiVersion = apiVersionData?.apiVersion;
  const latestReleaseNotifier = latestReleaseNotifierData?.latestReleaseNotifier;
  const latestViewedReleaseNotesVersion = meData?.me?.lastViewedReleaseNotesVersion;

  const markReleaseNotesAsViewed = () => {
    if (!latestReleaseNotifier?.version) {
      return;
    }

    updateUserLastViewedReleaseNotesVersion({
      variables: {
        version: latestReleaseNotifier?.version,
      },
    });
  };

  const onClick = () => {
    markReleaseNotesAsViewed();
    if (latestReleaseNotifier?.releaseNotesUrl) {
      window.open(latestReleaseNotifier.releaseNotesUrl, '_blank');
    }
  };

  const onClose = () => {
    markReleaseNotesAsViewed();
  };

  if (loading) {
    return <>{children}</>;
  }

  if (needsRefresh(apiVersion, APP_VERSION)) {
    // refresh the client
    window.location.reload();
  }

  if (
    showReleaseNotesBanner(
      latestViewedReleaseNotesVersion ?? undefined,
      latestReleaseNotifier?.version ?? undefined
    )
  ) {
    return (
      <>
        {children}
        <ReleaseNotifier
          onClick={onClick}
          onClose={onClose}
          title={latestReleaseNotifier?.title ?? ''}
          description={latestReleaseNotifier?.description ?? ''}
          releaseNotesUrl={latestReleaseNotifier?.releaseNotesUrl ?? ''}
        />
      </>
    );
  }

  return <>{children}</>;
};

function needsRefresh(apiVersion?: string, webVersion?: string) {
  if (!(apiVersion && webVersion)) {
    return false;
  }

  try {
    return semver.lt(webVersion, apiVersion);
  } catch (error) {
    console.error(error);
    return false;
  }
}

function showReleaseNotesBanner(
  latestViewedReleaseNotesVersion?: string,
  latestReleaseVersion?: string
) {
  if (!latestReleaseVersion) {
    return false;
  }

  if (!latestViewedReleaseNotesVersion) {
    return true;
  }

  try {
    return semver.lt(latestViewedReleaseNotesVersion, latestReleaseVersion);
  } catch (error) {
    console.error(error);
    return false;
  }
}

const ReleaseNotifier: FC<{
  onClick: () => void;
  onClose: () => void;
  title: string;
  description: string;
  releaseNotesUrl: string;
}> = ({ onClick, onClose, title, description, releaseNotesUrl }) => {
  return (
    <motion.div
      onClick={onClick}
      initial={{ translateY: 100, opacity: 0 }}
      animate={{ translateY: 0, opacity: 1 }}
      exit={{ translateY: 100, opacity: 0 }}
      className="fixed bottom-8 right-8 z-50 w-[400px] cursor-pointer rounded-xl border border-gray-50 bg-white p-4 shadow-md"
    >
      <div className="absolute right-1 top-1">
        <IconButton
          Icon={XMarkIcon}
          onClick={onClose}
          tooltipText="Close"
          variant="light"
          size="small"
        />
      </div>
      <div className="flex gap-x-4">
        <div className="flex h-[72px] w-[72px] items-center justify-center rounded-full">
          <Mascot />
        </div>
        <div className="flex flex-1 flex-col">
          <div className="font-semibold text-text-dark">{title}</div>
          <div className="flex-1 text-sm text-text-medium">{description}</div>
          <div className="self-end">
            <Link
              RightIcon={ArrowTopRightOnSquareIcon}
              size="medium"
              onClick={() => {
                if (releaseNotesUrl) {
                  window.open(releaseNotesUrl, '_blank');
                }
              }}
            >
              Read More
            </Link>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
