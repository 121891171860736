import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { navigate, routes } from '@redwoodjs/router';
import EarnMoney from 'src/assets/EarnMoney.svg';
import { Link } from 'src/components';

export const ReferralCard = () => {
  return (
    <div className="flex max-w-lg gap-x-4 rounded-xl border border-coscribe bg-white p-4">
      <EarnMoney height={108} width={108} />

      <div className="flex flex-col gap-y-2">
        <h2 className=" font-semibold text-coscribe">Earn £200 when you refer an agency!</h2>
        <p className="text-text-medium">Refer an agency and earn £200 after their third month.</p>
        <div className="self-end">
          <Link
            RightIcon={ArrowTopRightOnSquareIcon}
            size="medium"
            variant="primary"
            onClick={() => navigate(routes.settingsReferral())}
          >
            Learn more
          </Link>
        </div>
      </div>
    </div>
  );
};
