import { COVERSHEET_LAYOUT_FIELDS_FRAGMENT } from 'src/graphql/fragments';
export const GET_COVERSHEET_TEMPLATES_QUERY = gql`
  ${COVERSHEET_LAYOUT_FIELDS_FRAGMENT}
  query GetCoversheetTemplatesQuery {
    getCoversheetTemplates {
      id
      name
      preview {
        src
      }
      defaultLayout {
        ...CoversheetLayoutFields
      }
    }
  }
`;
