import { z } from 'zod';

const cvLayoutSchema = z.object({
  name: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
  jobTitle: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
  profileSummary: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
  experience: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
  education: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
  skills: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
  achievements: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
  certifications: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
  interests: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
  languages: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
  availability: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
  location: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
  refId: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
  rightToWork: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
  currentSalary: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
  desiredSalary: z.object({
    row: z.number(),
    column: z.number().nullish(),
    fixed: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
  }),
});

export const cvTemplateFormSchema = z.object({
  templateId: z.string().uuid().nullish(),
  accentColor: z.string().optional(),
  headerType: z.enum(['LOGO', 'LETTERHEAD']).optional(),
  logoAlignment: z.string().optional(),
  font: z.string().optional(),
  fontColor: z.string().optional(),
  headingFont: z.string().optional(),
  headingColor: z.string().optional(),
  fontScale: z.string().optional(),
  marginSize: z.string().optional(),
  capitaliseHeading: z.boolean().optional(),
  boldHeading: z.boolean().optional(),
  showBorder: z.boolean().optional(),
  showFooter: z.boolean().optional(),
  borderColor: z.string().optional(),
  defaultLayout: cvLayoutSchema.nullish(),
});

export type CvTemplateFormValues = z.infer<typeof cvTemplateFormSchema>;
