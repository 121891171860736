import { CV_LAYOUT_FIELDS_FRAGMENT } from './cvLayoutFieldsFragment';
import { COVERSHEET_MARKUP_FIELDS_FRAGMENT } from './coversheetMarkupFieldsFragment';
import { COVERSHEET_LAYOUT_FIELDS_FRAGMENT } from './coversheetLayoutFieldsFragment';

export const DOCUMENT_FIELDS_FRAGMENT = gql`
  ${CV_LAYOUT_FIELDS_FRAGMENT}
  ${COVERSHEET_LAYOUT_FIELDS_FRAGMENT}
  ${COVERSHEET_MARKUP_FIELDS_FRAGMENT}
  fragment DocumentFields on Document {
    ... on General {
      id
      title
      userId
      markup
      isActive
      myPermission
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobSocialPost {
      id
      title
      userId
      markup
      jobId
      myPermission
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobAdvert {
      id
      title
      userId
      jobId
      markup
      myPermission
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobSnapshot {
      id
      title
      userId
      jobId
      markup
      myPermission
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobReferenceCheck {
      id
      title
      userId
      jobId
      markup
      myPermission
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobInterviewQuestions {
      id
      title
      userId
      jobId
      markup
      myPermission
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobBooleanSearch {
      id
      title
      userId
      jobId
      markup
      myPermission
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobInMail {
      id
      title
      userId
      jobId
      markup
      myPermission
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobEmail {
      id
      title
      userId
      jobId
      markup
      myPermission
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on CandidateSnapshot {
      title
      id
      title
      userId
      markup
      myPermission
      isActive
      createdAt
      updatedAt
      candidateId
      title
      owner {
        id
        name
        email
      }
    }

    ... on CandidateIntroduction {
      title
      id
      title
      userId
      markup
      myPermission
      isActive
      createdAt
      updatedAt
      candidateId
      title
      owner {
        id
        name
        email
      }
    }

    ... on CandidateCv {
      id
      title
      userId
      markup
      templateId
      isActive
      myPermission
      createdAt
      updatedAt
      candidateId
      templateId
      structuredMarkup {
        name
        jobTitle
        profileSummary
        location
        availability
        refId
        rightToWork
        currentSalary
        desiredSalary
        experience {
          title
          company
          location
          startDate
          endDate
          description
        }
        education {
          title
          institution
          location
          startDate
          endDate
          description
        }
        skills {
          title
          description
        }
        achievements {
          description
        }
        certifications {
          title
          description
        }
        interests {
          description
        }
        languages {
          language
          level
        }
      }
      layout {
        ...CvLayoutFields
      }
      owner {
        id
        name
        email
      }
    }

    ... on CandidateCoversheet {
      id
      title
      userId
      markup
      isActive
      myPermission
      createdAt
      updatedAt
      candidateId
      templateId
      structuredMarkup {
        ...CoversheetMarkupFields
      }
      layout {
        ...CoversheetLayoutFields
      }
      owner {
        id
        name
        email
      }
    }

    ... on CompanySnapshot {
      id
      title
      userId
      markup
      isActive
      myPermission
      createdAt
      updatedAt
      companyId
      owner {
        id
        name
        email
      }
    }

    ... on CompanyIntroduction {
      id
      title
      userId
      markup
      isActive
      myPermission
      createdAt
      updatedAt
      companyId
      owner {
        id
        name
        email
      }
    }

    ... on JobInterviewPreparation {
      id
      title
      userId
      jobId
      markup
      myPermission
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }
    ... on ClientProposal {
      id
      title
      userId
      markup
      isActive
      myPermission
      createdAt
      updatedAt
      input {
        clientCompanyName
        yourCompanyName
        clientCompanyUSPs
        yourCompanyUSPs
      }
      owner {
        id
        name
        email
      }
    }

    ... on BlogPost {
      id
      title
      userId
      markup
      isActive
      myPermission
      createdAt
      updatedAt
      input {
        industry
        targetAudience
        topic
        type
        informationToInclude
      }
      owner {
        id
        name
        email
      }
    }

    ... on BlogPostIdeas {
      id
      title
      userId
      markup
      isActive
      myPermission
      createdAt
      updatedAt
      input {
        industry
        candidates
        clients
      }
      owner {
        id
        name
        email
      }
    }
    ... on MarketingStrategy {
      id
      title
      userId
      markup
      isActive
      myPermission
      createdAt
      updatedAt
      input {
        industry
        targetCandidates
        targetClients
      }
      owner {
        id
        name
        email
      }
    }
    ... on TestimonialRequest {
      id
      title
      userId
      markup
      isActive
      myPermission
      createdAt
      updatedAt
      input {
        candidateName
        companyName
        recipient
        recipientName
        duration
        servicesProvided
      }
      owner {
        id
        name
        email
      }
    }

    ... on ClientOutreach {
      id
      title
      userId
      jobId
      markup
      myPermission
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }
  }
`;
