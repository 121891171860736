// Hook up DocumentLayoutEditor to react-hook-form

import { useFormContext } from 'react-hook-form';
import { DocumentLayoutEditor } from './DocumentLayoutEditor';
import { Controller } from 'react-hook-form';
import { FC } from 'react';

type DocumentLayoutEditorFieldProps = {
  name: string;
  documentType: 'CandidateCv' | 'CandidateCoversheet';
};

export const DocumentLayoutEditorField: FC<DocumentLayoutEditorFieldProps> = ({
  name,
  documentType,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <>
          {value ? (
            <DocumentLayoutEditor onChange={onChange} value={value} documentType={documentType} />
          ) : null}
        </>
      )}
    />
  );
};
