export const SKILL_LEVEL_OPTIONS = [
  { label: 'Beginner', value: 1 },
  { label: 'Intermediate', value: 3 },
  { label: 'Advanced', value: 4 },
  { label: 'Expert', value: 5 },
];

export const getSkillLevelLabel = (value: number) => {
  const option = SKILL_LEVEL_OPTIONS.find((opt) => opt.value === value);
  return option ? option.label : 'Unknown';
};
