import { z } from 'zod';

export const candidateCvDocumentMarkupFormSchema = z.object({
  name: z.string().max(255).nullish(),
  jobTitle: z.string().max(255).nullish(),
  profileSummary: z.string().max(10000).nullish(),
  location: z.string().max(255).nullish(),
  availability: z.string().max(1000).nullish(),
  refId: z.string().max(1000).nullish(),
  rightToWork: z.string().max(1000).nullish(),
  currentSalary: z.string().max(1000).nullish(),
  desiredSalary: z.string().max(1000).nullish(),
  experience: z
    .array(
      z.object({
        startDate: z.date().nullish(),
        endDate: z.date().nullish(),
        title: z.string().max(255),
        company: z.string().max(255).nullish(),
        location: z.string().max(255).nullish(),
        description: z.string().max(5000).nullish(),
      })
    )
    .nullish(),
  education: z
    .array(
      z.object({
        startDate: z.date().nullish(),
        endDate: z.date().nullish(),
        title: z.string().max(255),
        institution: z.string().max(255).nullish(),
        location: z.string().max(255).nullish(),
        description: z.string().max(4000).nullish(),
      })
    )
    .nullish(),
  skills: z
    .array(
      z.object({
        title: z.string().max(255),
        description: z.string().max(255).nullish(),
      })
    )
    .nullish(),
  achievements: z
    .array(
      z.object({
        description: z.string().max(2000),
      })
    )
    .nullish(),
  certifications: z
    .array(
      z.object({
        title: z.string().max(255),
        description: z.string().max(1200).nullish(),
      })
    )
    .nullish(),
  interests: z
    .array(
      z.object({
        description: z.string().max(255),
      })
    )
    .nullish(),
  languages: z
    .array(
      z.object({
        language: z.string().max(255),
        level: z.number().int().min(1).max(5).nullish(),
      })
    )
    .nullish(),
  layout: z
    .record(
      z.string(),
      z.object({
        row: z.number(),
        column: z.number().nullish(),
        fixed: z.boolean().nullish(),
      })
    )
    .nullish(),
});

export type CandidateCvDocumentMarkupFormValues = z.infer<
  typeof candidateCvDocumentMarkupFormSchema
>;

export const candidateCoversheetFormSchema = z.object({
  name: z.string().nullish(),
  jobTitle: z.string().nullish(),
  profileIntroduction: z.string().nullish(),
  sectors: z.string().nullish(),
  desiredJobTitle: z.string().nullish(),
  desiredSalary: z.string().nullish(),
  location: z.string().nullish(),
  noticePeriod: z.string().nullish(),
  refId: z.string().nullish(),
  rightToWork: z.string().nullish(),
  consultantDetails: z.string().nullish(),
  relevantSkills: z.array(
    z.object({
      title: z.string(),
    })
  ),
  qualifications: z.array(
    z.object({
      description: z.string().nullish(),
    })
  ),
  layout: z
    .record(
      z.string(),
      z.object({ row: z.number(), column: z.number().nullish(), fixed: z.boolean().nullish() })
    )
    .nullish(),
});

export type CandidateCoversheetFormValues = z.infer<typeof candidateCoversheetFormSchema>;
