import { useQuery } from '@redwoodjs/web';
import { Spinner } from '../../components/Spinner';
import { usePageClasses } from '../../hooks';
import { TemplateDocument } from './TemplateDocument';
import { GeneralDocument } from './GeneralDocument';
import { isTemplateDoc } from 'src/lib/document';
import { GetDocumentQuery, GetDocumentQueryVariables } from 'types/graphql';
import { GET_DOCUMENT_QUERY } from 'src/graphql/queries/getDocumentQuery';
import { StructuredDocument } from 'src/components/StructuredDocument';

export type DocumentPageProps = {
  documentId: string;
  candidateId?: string;
};

const DocumentPage = ({ documentId, candidateId }: DocumentPageProps) => {
  usePageClasses('bg-pageGray');

  const { data, loading, error } = useQuery<GetDocumentQuery, GetDocumentQueryVariables>(
    GET_DOCUMENT_QUERY,
    {
      variables: {
        id: documentId,
      },
    }
  );

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    throw error;
  }

  const documentData = data?.document;

  if (!documentData) {
    return null;
  }

  if (isStructuredDocument(documentData)) {
    return <StructuredDocument candidateId={candidateId} />;
  }

  const isOwner = data?.document?.myPermission === 'OWNER';
  const isTemplateDocument = isTemplateDoc(documentData);

  if (isTemplateDocument && isOwner) {
    return <TemplateDocument document={documentData} />;
  } else {
    return <GeneralDocument document={documentData} />;
  }
};

function isStructuredDocument(document: GetDocumentQuery['document']) {
  return document?.__typename === 'CandidateCv' || document?.__typename === 'CandidateCoversheet';
}

export default DocumentPage;
