import { CV_LAYOUT_FIELDS_FRAGMENT } from '../fragments';
export const GET_CV_TEMPLATES_QUERY = gql`
  ${CV_LAYOUT_FIELDS_FRAGMENT}
  query GetCvTemplatesQuery {
    getCvTemplates {
      id
      name
      preview {
        src
      }
      defaultLayout {
        ...CvLayoutFields
      }
    }
  }
`;
