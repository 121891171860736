import { CheckIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { classNames } from '../../lib';
import { useController, useFormContext } from 'react-hook-form';
import { useReadOnlyForm } from '../../hooks';

export const Checkbox: FC<{
  label?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
}> = ({ label, value, onChange, disabled }) => {
  const onClickCheck = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (disabled) return;
    onChange?.(!value);
  };

  return (
    <div className="flex items-center space-x-2">
      <div
        onClick={onClickCheck}
        onMouseOver={(e) => e.stopPropagation()}
        className={classNames(
          'flex h-5 w-5 items-center justify-center rounded-md border border-text-medium hover:cursor-pointer',
          value ? 'bg-primary-medium' : disabled ? '' : 'bg-white hover:bg-gray-50'
        )}
      >
        {value && (
          <CheckIcon className="h-full w-full text-white" aria-hidden="true" strokeWidth={'2'} />
        )}
      </div>
      <span className=" text-sm font-medium text-text-medium">{label}</span>
    </div>
  );
};

export type CheckboxFieldProps = {
  name: string;
  label?: string;
  disabled?: boolean;
};

export const CheckboxField: FC<CheckboxFieldProps> = ({ name, label, disabled }) => {
  const { control, watch } = useFormContext();
  const { readOnly } = useReadOnlyForm();
  const { field } = useController({
    name,
    control,
    defaultValue: false,
  });

  const value = watch(name);

  if (readOnly) {
    return (
      <div className="flex items-center space-x-2">
        <div
          className={classNames(
            'flex h-5 w-5 items-center justify-center rounded-md border border-text-medium',
            value ? 'bg-primary-medium' : 'bg-white'
          )}
        >
          {value && (
            <CheckIcon className="h-full w-full text-white" aria-hidden="true" strokeWidth={'2'} />
          )}
        </div>
        <span className="text-sm font-medium text-text-medium">{label}</span>
      </div>
    );
  }

  return (
    <div className="flex items-center space-x-2">
      {/*
        This hidden checkbox is included to maintain form state with react-hook-form.
        It receives the field props from useController and handles the actual form submission,
        while the visible Checkbox component handles the UI presentation.
      */}
      <input type="checkbox" className="hidden" disabled={disabled} {...field} />
      <Checkbox
        label={label}
        value={value}
        onChange={(checked) => field.onChange(checked)}
        disabled={disabled}
      />
    </div>
  );
};
