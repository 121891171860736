import { FC, useEffect } from 'react';
import { useMutation, useQuery } from '@redwoodjs/web';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { GET_COVERSHEET_CONFIG_QUERY, GET_COVERSHEET_TEMPLATES_QUERY } from 'src/graphql/queries';
import { UPDATE_COVERSHEET_CONFIG_MUTATION } from 'src/graphql/mutations';
import {
  GetBrandAssets,
  GetCoversheetConfig,
  GetCoversheetTemplatesQuery,
  GetCoversheetTemplatesQueryVariables,
  UpdateCoversheetConfig,
  UpdateCoversheetConfigVariables,
} from 'types/graphql';
import {
  Form,
  SelectField,
  Tooltip,
  ColorPickerField,
  Toggle,
  Spinner,
  PdfDocumentTemplateCarousel,
} from 'src/components';
import {
  CoversheetTemplateFormValues,
  coversheetTemplateFormSchema,
} from './coversheetTemplateFormSchema';
import { useDebounce } from 'src/hooks';
import { AlignmentSelectionGroup } from '../../../components';
import { alignmentOptions } from '../../../constants';
import { stripTypenames } from 'src/lib/object';
import { FadeIn } from 'src/components/FadeIn';
import { DocumentLayoutEditorField } from 'src/components/DocumentLayoutEditor';

const DEFAULT_COVERSHEET_TEMPLATE_VALUES: CoversheetTemplateFormValues = {
  templateId: '',
  accentColor: '',
  logoAlignment: 'left',
  font: 'Roboto',
  fontColor: '#000000',
  capitaliseHeading: false,
  boldHeading: false,
  showBorder: true,
  showFooter: true,
  borderColor: '#000000',
  marginSize: 'medium',
  headingFont: 'Roboto',
  headingColor: '#000000',
  fontScale: 'medium',
  headerType: 'LOGO',
  defaultLayout: null,
};

export const CoversheetTabForm: FC<{
  coversheetConfigData: GetCoversheetConfig['getCoversheetConfig'];
  onTemplateSelect: (templateId: string, layout: unknown) => void;
  selectedTemplateId?: string | null;
  brandAssetsData: GetBrandAssets['getBrandAssets'];
}> = ({ coversheetConfigData, onTemplateSelect, selectedTemplateId, brandAssetsData }) => {
  const [updateCoversheetConfig] = useMutation<
    UpdateCoversheetConfig,
    UpdateCoversheetConfigVariables
  >(UPDATE_COVERSHEET_CONFIG_MUTATION, {
    refetchQueries: [GET_COVERSHEET_CONFIG_QUERY],
  });

  const CONFIG_FORM_VALUES: CoversheetTemplateFormValues = {
    templateId: coversheetConfigData?.coversheetTemplate?.id ?? selectedTemplateId ?? '',
    headerType: coversheetConfigData?.headerType || getDefaultHeaderType(brandAssetsData),
    logoAlignment:
      coversheetConfigData?.logoAlignment || DEFAULT_COVERSHEET_TEMPLATE_VALUES.logoAlignment,
    font: coversheetConfigData?.font || DEFAULT_COVERSHEET_TEMPLATE_VALUES.font,
    fontColor: coversheetConfigData?.fontColor || DEFAULT_COVERSHEET_TEMPLATE_VALUES.fontColor,
    capitaliseHeading:
      coversheetConfigData?.capitaliseHeading ??
      DEFAULT_COVERSHEET_TEMPLATE_VALUES.capitaliseHeading,
    boldHeading:
      coversheetConfigData?.boldHeading ?? DEFAULT_COVERSHEET_TEMPLATE_VALUES.boldHeading,
    showBorder: coversheetConfigData?.showBorder ?? DEFAULT_COVERSHEET_TEMPLATE_VALUES.showBorder,
    showFooter: coversheetConfigData?.showFooter ?? DEFAULT_COVERSHEET_TEMPLATE_VALUES.showFooter,
    borderColor:
      coversheetConfigData?.borderColor || DEFAULT_COVERSHEET_TEMPLATE_VALUES.borderColor,
    marginSize: coversheetConfigData?.marginSize || DEFAULT_COVERSHEET_TEMPLATE_VALUES.marginSize,
    headingFont:
      coversheetConfigData?.headingFont || DEFAULT_COVERSHEET_TEMPLATE_VALUES.headingFont,
    headingColor:
      coversheetConfigData?.headingColor || DEFAULT_COVERSHEET_TEMPLATE_VALUES.headingColor,
    fontScale: coversheetConfigData?.fontScale || DEFAULT_COVERSHEET_TEMPLATE_VALUES.fontScale,
    defaultLayout: coversheetConfigData?.defaultLayout
      ? stripTypenames(coversheetConfigData?.defaultLayout)
      : coversheetConfigData?.coversheetTemplate?.defaultLayout
      ? stripTypenames(coversheetConfigData?.coversheetTemplate?.defaultLayout)
      : DEFAULT_COVERSHEET_TEMPLATE_VALUES.defaultLayout,
  };

  const formMethods = useForm<CoversheetTemplateFormValues>({
    resolver: zodResolver(coversheetTemplateFormSchema),
    defaultValues: CONFIG_FORM_VALUES,
  });

  const formValues = formMethods.watch();
  const debouncedFormValues = useDebounce(formValues, 500);

  const {
    data: templatesData,
    loading: templatesLoading,
    error: templatesError,
  } = useQuery<GetCoversheetTemplatesQuery, GetCoversheetTemplatesQueryVariables>(
    GET_COVERSHEET_TEMPLATES_QUERY
  );

  useEffect(() => {
    if (selectedTemplateId) {
      updateCoversheetConfig({
        variables: {
          input: {
            ...debouncedFormValues,
            templateId: selectedTemplateId,
          },
        },
      });
    }
  }, [debouncedFormValues]);

  // TODO: Create a controlled component for the carousel to avoid this workaround
  const handleTemplateSelect = (templateId: string) => {
    if (templateId === selectedTemplateId) return;

    const template = templatesData?.getCoversheetTemplates.find(
      (template) => template.id === templateId
    );
    const layout = template?.defaultLayout ? stripTypenames(template.defaultLayout) : null;

    formMethods.reset({
      ...debouncedFormValues,
      templateId,
      defaultLayout: layout,
    });

    onTemplateSelect(templateId, layout);
  };

  useEffect(() => {
    if (!selectedTemplateId && templatesData?.getCoversheetTemplates.length) {
      handleTemplateSelect(templatesData.getCoversheetTemplates[1].id);
    }
  }, [selectedTemplateId, templatesData]);

  if (templatesLoading) {
    return <Spinner />;
  }

  if (!templatesData || templatesError) {
    throw new Error('Failed to fetch Cover Sheet templates');
  }

  return (
    <Form<CoversheetTemplateFormValues> formMethods={formMethods} className="space-y-6">
      <div>
        <h2 className="text-lg font-bold text-text-dark">Template</h2>
        <p className="text-sm font-normal text-text-medium">
          Select and customise your Cover Sheet template. These will apply to your workspace.
        </p>
      </div>

      {/* Carousel Section */}
      <div className="relative -mx-12 border-b border-t border-text-light bg-gray-100 py-6">
        <div className="w-full px-12">
          <PdfDocumentTemplateCarousel
            templates={templatesData.getCoversheetTemplates ?? []}
            onSelectTemplate={handleTemplateSelect}
            selectedTemplateId={selectedTemplateId}
          />
        </div>
      </div>

      <div className="flex flex-grow flex-col justify-center gap-8 pt-5">
        {/* Header Type Selection */}
        <div className="flex items-center space-x-4">
          <Label>Header:</Label>
          <div>
            <SelectField
              name="headerType"
              options={getHeaderTypeDropdownOptions(brandAssetsData)}
            />
          </div>
        </div>

        {formValues.headerType === 'LOGO' && (
          <>
            {/* Logo Alignment */}
            <div className="flex items-center space-x-4">
              <Label>Align Logo:</Label>
              <AlignmentSelectionGroup name="logoAlignment" options={alignmentOptions} />
            </div>

            {/* Margin Size Selection */}
            <div className="flex items-center space-x-4">
              <Label>Margin:</Label>
              <div className="max-w-[200px]">
                <SelectField
                  name="marginSize"
                  options={[
                    { label: 'Small', value: 'small' },
                    { label: 'Medium', value: 'medium' },
                    { label: 'Large', value: 'large' },
                  ]}
                />
              </div>
            </div>
          </>
        )}

        {/* Font Selection */}
        <div className="flex items-center space-x-4">
          <Label>Font:</Label>
          <div className="flex max-w-[200px] items-center gap-8">
            <div className="min-w-28">
              <SelectField
                name="font"
                options={[
                  { label: 'Roboto', value: 'Roboto' },
                  { label: 'Libre Baskerville', value: 'Libre Baskerville' },
                  { label: 'Mono', value: 'Roboto Mono' },
                ]}
              />
            </div>
            <ColorPickerField
              name="fontColor"
              colorRecommendations={['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF']}
            />
          </div>
        </div>

        {/* Font Scale Selection */}
        <div className="flex items-center space-x-4">
          <Label>Font scale:</Label>
          <SelectField
            name="fontScale"
            options={[
              { label: 'Small', value: 'small' },
              { label: 'Medium', value: 'medium' },
              { label: 'Large', value: 'large' },
            ]}
          />
        </div>

        {/* Headings Section */}
        <div className="flex items-center space-x-4">
          <div className="flex w-full items-center gap-3">
            <Label>Headings:</Label>
            <div className="flex flex-wrap items-center gap-8">
              <div className="min-w-28">
                <SelectField
                  name="headingFont"
                  options={[
                    { label: 'Roboto', value: 'Roboto' },
                    { label: 'Libre Baskerville', value: 'Libre Baskerville' },
                    { label: 'Mono', value: 'Roboto Mono' },
                  ]}
                />
              </div>
              <div className="flex items-center gap-x-3">
                <Tooltip innerBody="Bold headings" position="top">
                  <button
                    type="button"
                    className={`flex h-10 w-10 items-center justify-center rounded border bg-white transition-colors duration-150 ${
                      formValues.boldHeading
                        ? 'border-primary-medium bg-gray-50 text-primary-medium'
                        : 'border-text-light text-text-medium hover:border-text-medium hover:bg-gray-50'
                    }`}
                    onClick={() => formMethods.setValue('boldHeading', !formValues.boldHeading)}
                  >
                    <p className="text-sm font-medium">B</p>
                  </button>
                </Tooltip>

                <Tooltip innerBody="Uppercase headings" position="top">
                  <button
                    type="button"
                    className={`flex h-10 w-10 items-center justify-center rounded border bg-white transition-colors duration-150 ${
                      formValues.capitaliseHeading
                        ? 'border-primary-medium bg-gray-50 text-primary-medium'
                        : 'border-text-light text-text-medium hover:border-text-medium hover:bg-gray-50'
                    }`}
                    onClick={() =>
                      formMethods.setValue('capitaliseHeading', !formValues.capitaliseHeading)
                    }
                  >
                    <p className="text-sm font-medium">A↑</p>
                  </button>
                </Tooltip>
              </div>
              <ColorPickerField
                name="headingColor"
                colorRecommendations={['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF']}
              />
            </div>
          </div>
        </div>

        {/* Border Section */}
        <div className="flex min-h-10 items-center space-x-4">
          <Label>Border:</Label>
          <div className="flex w-full items-center gap-8">
            <Toggle
              enabled={!!formValues.showBorder}
              onChange={(enabled) => formMethods.setValue('showBorder', enabled)}
            />
            {formValues.showBorder && (
              <ColorPickerField
                name="borderColor"
                colorRecommendations={['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF']}
              />
            )}
          </div>
        </div>

        <FadeIn visible={formValues.headerType === 'LOGO'}>
          <div className="flex min-h-10 items-center space-x-4">
            <Label>Show footer:</Label>
            <Toggle
              enabled={!!formValues.showFooter}
              onChange={(enabled) => formMethods.setValue('showFooter', enabled)}
            />
          </div>
        </FadeIn>
      </div>

      <FadeIn visible={!!formValues.defaultLayout}>
        <div className="flex flex-col gap-y-1.5 pb-3">
          <h3 className="font-semibold text-text-dark">Layout</h3>
          <p className="text-sm text-text-medium">
            Set the default layout for Cover Sheets generated in your workspace.
          </p>
        </div>
        <DocumentLayoutEditorField name="defaultLayout" documentType="CandidateCoversheet" />
      </FadeIn>
    </Form>
  );
};

const Label: FC<{ children: React.ReactNode }> = ({ children }) => (
  <p className="min-w-28 font-medium text-text-dark">{children}</p>
);

function getDefaultHeaderType(brandAssetsData: GetBrandAssets['getBrandAssets']) {
  if (brandAssetsData?.letterhead) {
    return 'LETTERHEAD';
  }
  return 'LOGO';
}

function getHeaderTypeDropdownOptions(brandAssetsData: GetBrandAssets['getBrandAssets']) {
  const options = [
    { label: 'Logo', value: 'LOGO' },
    { label: 'Letterhead', value: 'LETTERHEAD' },
  ];

  if (!brandAssetsData?.letterhead) {
    return options.filter((option) => option.value !== 'LETTERHEAD');
  }

  return options;
}
