import { CV_LAYOUT_FIELDS_FRAGMENT } from '../fragments';

export const GET_CV_CONFIG_QUERY = gql`
  ${CV_LAYOUT_FIELDS_FRAGMENT}
  query GetCvConfig {
    getCvConfig {
      id
      CvTemplate {
        id
        defaultLayout {
          ...CvLayoutFields
        }
      }
      accentColor
      headerType
      logoAlignment
      font
      fontColor
      capitaliseHeading
      boldHeading
      showBorder
      showFooter
      borderColor
      marginSize
      headingFont
      headingColor
      fontScale
      defaultLayout {
        ...CvLayoutFields
      }
    }
  }
`;
