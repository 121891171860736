export const COVERSHEET_MARKUP_FIELDS_FRAGMENT = gql`
  fragment CoversheetMarkupFields on CoversheetOutput {
    name
    jobTitle
    profileIntroduction
    desiredJobTitle
    desiredSalary
    refId
    location
    sectors
    rightToWork
    noticePeriod
    consultantDetails
    relevantSkills {
      title
    }
    qualifications {
      description
    }
  }
`;
