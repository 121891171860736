import { FC, useMemo } from 'react';
import {
  ACCESS_LEVEL,
  ChangeDocumentStyleMutation,
  ChangeDocumentStyleMutationVariables,
  CoversheetInput,
  DocumentFieldsWithHistory,
  DocumentFieldsWithHistory_CandidateCoversheet_,
  DocumentFieldsWithHistory_CandidateCv_,
  GenerateCoversheetPdfQuery,
  GenerateCoversheetPdfQueryVariables,
} from 'types/graphql';
import { useMutation, useQuery } from '@redwoodjs/web';
import { CHANGE_DOCUMENT_STYLE_MUTATION } from 'src/graphql/mutations';
import { PdfViewer } from 'src/components/PdfViewer';
import { IconButton } from 'src/components/IconButton';
import { ArrowDownTrayIcon, PaintBrushIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { Spinner } from 'src/components/Spinner';
import { toast } from '@redwoodjs/web/dist/toast';
import {
  GET_COMPANY_AND_DOCUMENTS_QUERY,
  GET_CANDIDATE_AND_DOCUMENTS_QUERY,
  GET_DOCUMENT_QUERY,
  GET_JOB_AND_DOCUMENTS,
  GENERATE_CV_PDF_QUERY,
  GENERATE_COVERSHEET_PDF_QUERY,
} from 'src/graphql/queries';
import { ChangeStyleDialog } from './ChangeStyleDialog';
import { useDialog } from 'src/hooks';
import { ShareDocumentDialog } from 'src/components/ShareDialogs';
import { copyToClipboard } from 'src/lib';
import { DocumentTopBar } from './DocumentTopBar';
import { notNullish } from 'src/lib/guards';
import { navigate, routes } from '@redwoodjs/router';
import { stripTypenames } from 'src/lib/object';

type Props = {
  document: DocumentFieldsWithHistory_CandidateCv_ | DocumentFieldsWithHistory_CandidateCoversheet_;
  editMode?: boolean;
  candidateId?: string;
};

export const PdfDocumentEditor: FC<Props> = ({ document, editMode, candidateId }) => {
  const { close, show } = useDialog();
  const { __typename, ...structuredMarkup } = document.structuredMarkup;
  const {
    data: pdfData,
    previousData,
    loading,
    error,
  } = useQuery(GENERATE_CV_PDF_QUERY, {
    variables: {
      input: stripTypenames(structuredMarkup),
      layout: document.layout,
      templateId: document.templateId,
    },
    skip: document.__typename !== 'CandidateCv',
  });

  const {
    data: coversheetPdfData,
    previousData: coversheetPreviousData,
    loading: coversheetLoading,
    error: coversheetError,
  } = useQuery<GenerateCoversheetPdfQuery, GenerateCoversheetPdfQueryVariables>(
    GENERATE_COVERSHEET_PDF_QUERY,
    {
      variables: {
        input: stripTypenames({ ...structuredMarkup, layout: document.layout }) as CoversheetInput,
        templateId: document.templateId,
      },
      skip: document.__typename !== 'CandidateCoversheet',
    }
  );

  const [changeDocumentStyle, { loading: isChangingStyle }] = useMutation<
    ChangeDocumentStyleMutation,
    ChangeDocumentStyleMutationVariables
  >(CHANGE_DOCUMENT_STYLE_MUTATION, {
    refetchQueries: [
      GET_JOB_AND_DOCUMENTS,
      GET_COMPANY_AND_DOCUMENTS_QUERY,
      GET_CANDIDATE_AND_DOCUMENTS_QUERY,
      {
        query: GET_DOCUMENT_QUERY,
        variables: {
          id: document.id,
        },
      },
    ],
    onCompleted: () => {
      toast.success('Document updated with desired style!');
    },
  });

  const pdfContentData =
    pdfData?.generateCvPdf ??
    coversheetPdfData?.generateCoversheetPdf ??
    previousData?.generateCvPdf ??
    coversheetPreviousData?.generateCoversheetPdf;
  const dataUrl = useMemo(
    () => (pdfContentData ? `data:application/pdf;base64,${pdfContentData?.pdfContent}` : null),
    [pdfContentData]
  );

  const onDownload = () => {
    const link = window.document.createElement('a');
    link.href = dataUrl ?? '';
    link.download = `${document.__typename === 'CandidateCv' ? 'cv' : 'coversheet'}.pdf`;
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
  };

  const onChangeStyle = (document: DocumentFieldsWithHistory) => {
    document.config?.configSchema
      ? show(
          <ChangeStyleDialog
            document={document}
            template={document.config}
            onChangeStyle={(config, saveDefault, templateId) => {
              if (!(document?.config && document?.id)) {
                console.error('Tried to change a document style without a config or docId');
                return;
              }

              changeDocumentStyle({
                variables: {
                  input: {
                    config: JSON.stringify(config),
                    templateId: templateId ?? document.config.id,
                    id: document.id,
                    default: saveDefault ?? undefined,
                  },
                },
              });
              close();
            }}
            onClose={close}
          />
        )
      : undefined;
  };

  const handleCopyDocumentLink = async () => {
    const urlToCopy = `${window.location.origin}/document/${document?.id}`;
    await copyToClipboard(urlToCopy);
  };

  const dropdownButtons = [
    {
      text: 'Change Style',
      requiredPermission: 'OWNER' as ACCESS_LEVEL,
      onClick: onChangeStyle,
      Icon: PaintBrushIcon,
    },
    {
      text: 'Share Document',
      requiredPermission: 'OWNER' as ACCESS_LEVEL,
      Icon: UserPlusIcon,
      onClick: () => {
        show(
          <ShareDocumentDialog
            document={document}
            onClose={close}
            onCopy={handleCopyDocumentLink}
          />
        );
      },
    },
    {
      text: 'Export',
      requiredPermission: 'READ' as ACCESS_LEVEL,
      Icon: ArrowDownTrayIcon,
      onClick: () => onDownload(),
    },
  ];

  if (loading && !pdfContentData) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (error) {
    throw error;
  }

  return (
    <div className="relative flex flex-1 flex-col">
      <div className="flex flex-1 flex-col items-center">
        <DocumentTopBar
          document={document}
          myPermission={document.myPermission}
          dropdownButtons={dropdownButtons}
          permissionsData={document.permissions?.filter(notNullish) ?? []}
          onCopyLinkToDocument={handleCopyDocumentLink}
        />

        <div className="flex flex-1 flex-col items-center">
          <PdfViewer
            edit={
              !editMode
                ? {
                    text: `Edit ${document.__typename === 'CandidateCv' ? 'CV' : 'Coversheet'}`,
                    onClick: () => {
                      navigate(
                        routes.document({ documentId: document.id, candidateId: candidateId ?? '' })
                      );
                    },
                  }
                : undefined
            }
            loading={loading || coversheetLoading}
            fileUrl={dataUrl}
          />
        </div>
        <div className="mx-32 flex w-full min-w-[40ch] max-w-[70ch] justify-end border-t border-text-light pb-4 pt-2">
          <div>
            <IconButton
              tooltipText="Download"
              size="medium"
              variant="generate"
              Icon={ArrowDownTrayIcon}
              onClick={onDownload}
            />
          </div>
        </div>
      </div>
      {isChangingStyle && (
        <div className="absolute inset-0 rounded-[36px] bg-white opacity-80">
          <Spinner />
        </div>
      )}
    </div>
  );
};
