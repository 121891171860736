import { FC } from 'react';
import { documentPresentationalProperties } from 'src/lib/document';
import { DocumentFieldsWithHistory, PermissionFields } from 'types/graphql';
import { DropdownButton } from '../DropdownButton';
import { useDialog } from 'src/hooks';
import { ACCESS_LEVEL } from 'types/graphql';
import { hasRequiredAccess } from 'src/lib/accessControl';
import { NonOwnerViewDialog, ShareDocumentDialog } from '../ShareDialogs';
import { OtherPermissions } from '../OtherPermissions';
import { StatusPill } from 'src/components/StatusPill';

type DropdownButtonOption = {
  text: string;
  Icon: FC<React.ComponentProps<'svg'>>;
  onClick: (document: DocumentFieldsWithHistory) => void;
  requiredPermission?: ACCESS_LEVEL;
};

type Props = {
  document: DocumentFieldsWithHistory;
  myPermission?: ACCESS_LEVEL | null;
  permissionsData: PermissionFields[];
  dropdownButtons: DropdownButtonOption[];
  onCopyLinkToDocument: () => void;
};

export const DocumentTopBar: FC<Props> = ({
  document,
  myPermission,
  permissionsData,
  dropdownButtons,
  onCopyLinkToDocument,
}) => {
  const { Icon, color, title } = documentPresentationalProperties[document.__typename];
  const isOwner = hasRequiredAccess(myPermission, 'OWNER');
  const { show, close } = useDialog();

  const dropdownOptions = dropdownButtons
    .filter(({ requiredPermission }) => hasRequiredAccess(myPermission, requiredPermission))
    // Ensure that the onClick function receives the document as an argument
    .map(({ text, Icon, onClick }) => ({ text, Icon, onClick: () => onClick(document) }));

  return (
    <div className="flex w-full justify-center py-2">
      <div className="mx-32 flex min-w-[40ch]  max-w-[70ch] flex-1 items-center justify-between border-b border-text-light">
        <div className="flex items-center justify-start gap-x-3">
          <Icon className="h-4 w-4" style={{ color }} />
          <h3 className="text-text-dark">{title}</h3>
          {document.__typename === 'CandidateCoversheet' && (
            <StatusPill text="BETA" size="sm" color="cyan" />
          )}
        </div>

        <div className="flex flex-row items-center">
          <div className="mr-3 flex items-end justify-end gap-2 ">
            {document.permissions && (
              <OtherPermissions
                permissionsData={permissionsData}
                onClick={() => {
                  isOwner
                    ? show(
                        <ShareDocumentDialog
                          document={document}
                          onClose={close}
                          onCopy={onCopyLinkToDocument}
                        />
                      )
                    : show(
                        <NonOwnerViewDialog
                          onClose={close}
                          permissionsData={permissionsData ?? []}
                        />
                      );
                }}
              />
            )}
          </div>
          <DropdownButton options={dropdownOptions} />
        </div>
      </div>
    </div>
  );
};
