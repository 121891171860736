import { FC, useEffect, useRef, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormError, Link, SelectField, Tabs, TextAreaField, TextField } from 'src/components';
import { DatePickerField } from 'src/components/DatePicker';
import { RichTextField } from '../RichTextField';
import { ArrowLeftIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Form } from 'src/components/Form/Form';
import { Reorder } from 'framer-motion';
import { DraggableItem } from 'src/components/DraggableItem';
import { useMutation } from '@redwoodjs/web';
import { UPDATE_STRUCTURED_DOCUMENT_MUTATION } from 'src/graphql/mutations';
import {
  CandidateCvDocumentMarkupFormValues,
  candidateCvDocumentMarkupFormSchema,
} from './structuredDocumentFormSchema';
import { Accordion } from '../Accordion';
import { useBoolean, useDebounce } from 'src/hooks';
import {
  CandidateCv,
  CvInput,
  UpdateStructuredDocument,
  UpdateStructuredDocumentVariables,
} from 'types/graphql';
import { formatStartEndDateSnippet } from 'src/lib/candidateUtil';
import { htmlToText } from 'html-to-text';
import { getLanguageLevelLabel } from 'src/lib/sharedConstants';
import { serializeDate } from 'src/lib/dateTime';
import { PageTitle } from '../PageTitle';
import { navigate, routes } from '@redwoodjs/router';
import { Toggle } from '../Toggle';
import { DocumentLayoutEditorField } from '../DocumentLayoutEditor';
import { stripTypenames } from 'src/lib/object';
type Props = {
  document: Pick<CandidateCv, 'id' | 'structuredMarkup' | 'title' | 'layout'>;
  candidateId?: string;
};

const TABS = ['CV Details', 'Layout'] as const;
type Tabs = (typeof TABS)[number];

export const CvMarkupEditor: FC<Props> = ({ document, candidateId }) => {
  const [tab, setTab] = useState<Tabs>('CV Details');
  const { value: includeLanguageLevel, setValue: setIncludeLanguageLevel } = useBoolean(false);

  const { __typename, ...documentMarkup } = document.structuredMarkup;
  const markup = {
    ...documentMarkup,
    certifications: documentMarkup.certifications?.map(({ __typename, ...c }) => c),
    experience: documentMarkup.experience?.map(({ __typename, ...e }) => e),
    education: documentMarkup.education?.map(({ __typename, ...ed }) => ed),
    skills: documentMarkup.skills?.map(({ __typename, ...s }) => s),
    achievements: documentMarkup.achievements?.map(({ __typename, ...a }) => a),
    interests: documentMarkup.interests?.map(({ __typename, ...i }) => i),
    languages: documentMarkup.languages?.map(({ __typename, ...l }) => l),
  };

  const formMethods = useForm<CandidateCvDocumentMarkupFormValues>({
    resolver: zodResolver(candidateCvDocumentMarkupFormSchema),
    defaultValues: {
      name: markup?.name,
      jobTitle: markup?.jobTitle,
      profileSummary: markup?.profileSummary,
      location: markup?.location,
      availability: markup?.availability,
      refId: markup?.refId,
      rightToWork: markup?.rightToWork,
      currentSalary: markup?.currentSalary,
      desiredSalary: markup?.desiredSalary,
      experience:
        markup?.experience?.map((exp) => ({
          startDate: exp.startDate ? new Date(exp.startDate) : null,
          endDate: exp.endDate ? new Date(exp.endDate) : null,
          location: exp.location,
          description: exp.description,
          company: exp.company,
          title: exp.title,
        })) ?? [],
      education:
        markup?.education?.map((edu) => ({
          title: edu.title,
          institution: edu.institution,
          location: edu.location,
          description: edu.description,
          startDate: edu.startDate ? new Date(edu.startDate) : null,
          endDate: edu.endDate ? new Date(edu.endDate) : null,
        })) ?? [],
      skills:
        markup?.skills?.map((skill) => ({
          title: skill.title,
          description: skill.description,
        })) ?? [],
      achievements:
        markup?.achievements?.map((ach) => ({
          description: ach.description,
        })) ?? [],
      certifications:
        markup?.certifications?.map((cert) => ({
          title: cert.title,
          description: cert.description,
        })) ?? [],
      languages:
        markup?.languages?.map((lang) => ({
          language: lang.language,
          level: lang.level,
        })) ?? [],
      interests:
        markup?.interests?.map((interest) => ({
          description: interest.description,
        })) ?? [],
      layout: document.layout ? stripTypenames(document.layout) : null,
    },
  });

  const [openAccordions, setOpenAccordions] = useState<{ [key: string]: boolean }>({});
  const { value: isAccordionOpen, setTrue: openAllAccordions } = useBoolean(false);

  const formErrorRef = useRef<{ [key: string]: HTMLDivElement | null }>({});

  // Open all accordions when there is any form error and scroll into view the first error field
  useEffect(() => {
    const errors = formMethods.formState.errors;
    if (Object.keys(errors).length > 0) {
      openAllAccordions();
      const firstErrorKey = Object.keys(errors)[0];
      const firstErrorRef = formErrorRef.current[firstErrorKey];
      if (firstErrorRef) {
        firstErrorRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [formMethods.formState.errors]);

  const [updateDocument, { loading: updateDocumentLoading, error }] = useMutation<
    UpdateStructuredDocument,
    UpdateStructuredDocumentVariables
  >(UPDATE_STRUCTURED_DOCUMENT_MUTATION);

  // Managing dynamic fields using useFieldArray
  const {
    fields: experienceFields,
    append: appendExperience,
    remove: removeExperience,
    replace: replaceExperiences,
  } = useFieldArray({ control: formMethods.control, name: 'experience', keyName: 'fieldId' });

  const {
    fields: educationFields,
    append: appendEducation,
    remove: removeEducation,
    replace: replaceEducation,
  } = useFieldArray({ control: formMethods.control, name: 'education', keyName: 'fieldId' });

  const {
    fields: skillFields,
    append: appendSkill,
    remove: removeSkill,
    replace: replaceSkills,
  } = useFieldArray({ control: formMethods.control, name: 'skills', keyName: 'fieldId' });

  const {
    fields: achievementFields,
    append: appendAchievement,
    remove: removeAchievement,
    replace: replaceAchievements,
  } = useFieldArray({ control: formMethods.control, name: 'achievements', keyName: 'fieldId' });

  const {
    fields: certificationFields,
    append: appendCertification,
    remove: removeCertification,
    replace: replaceCertifications,
  } = useFieldArray({ control: formMethods.control, name: 'certifications', keyName: 'fieldId' });

  const {
    fields: languageFields,
    append: appendLanguage,
    remove: removeLanguage,
    replace: replaceLanguages,
  } = useFieldArray({ control: formMethods.control, name: 'languages', keyName: 'fieldId' });

  const {
    fields: interestFields,
    append: appendInterest,
    remove: removeInterest,
    replace: replaceInterests,
  } = useFieldArray({ control: formMethods.control, name: 'interests', keyName: 'fieldId' });

  const liveExperiences = formMethods.watch('experience') ?? [];
  const mergedExperienceFields = experienceFields.map((f, i) => ({
    ...f,
    ...liveExperiences?.[i],
  }));

  const liveEducation = formMethods.watch('education') ?? [];
  const mergedEducationFields = educationFields.map((f, i) => ({
    ...f,
    ...liveEducation?.[i],
  }));

  const liveSkills = formMethods.watch('skills') ?? [];
  const mergedSkillFields = skillFields.map((f, i) => ({
    ...f,
    ...liveSkills?.[i],
  }));

  const liveAchievements = formMethods.watch('achievements') ?? [];
  const mergedAchievementFields = achievementFields.map((f, i) => ({
    ...f,
    ...liveAchievements?.[i],
  }));

  const liveLanguages = formMethods.watch('languages') ?? [];
  const mergedLanguageFields = languageFields.map((f, i) => ({
    ...f,
    ...liveLanguages?.[i],
  }));

  const liveInterests = formMethods.watch('interests') ?? [];
  const mergedInterestFields = interestFields.map((f, i) => ({
    ...f,
    ...liveInterests?.[i],
  }));

  const liveCertifications = formMethods.watch('certifications') ?? [];
  const mergedCertificationFields = certificationFields.map((f, i) => ({
    ...f,
    ...liveCertifications?.[i],
  }));

  const watchedFormValues: CandidateCvDocumentMarkupFormValues = {
    ...formMethods.watch(),
    experience: mergedExperienceFields,
    education: mergedEducationFields,
    skills: mergedSkillFields,
    achievements: mergedAchievementFields,
    languages: mergedLanguageFields,
    interests: mergedInterestFields,
    certifications: mergedCertificationFields,
  };

  const debouncedValues = useDebounce(watchedFormValues, 500);
  const prevValuesRef = useRef<CandidateCvDocumentMarkupFormValues | null>(null);

  useEffect(() => {
    const prevValues = prevValuesRef.current;

    const hasChanged = JSON.stringify(debouncedValues) !== JSON.stringify(prevValues);
    const saveChanges = async () => {
      try {
        // Transform form values to match the GraphQL input types
        const transformedValues = {
          name: debouncedValues.name,
          jobTitle: debouncedValues.jobTitle,
          profileSummary: debouncedValues.profileSummary,
          location: debouncedValues.location,
          availability: debouncedValues.availability,
          refId: debouncedValues.refId,
          rightToWork: debouncedValues.rightToWork,
          currentSalary: debouncedValues.currentSalary,
          desiredSalary: debouncedValues.desiredSalary,
          experience: debouncedValues.experience?.map((exp) => ({
            startDate: exp.startDate ? serializeDate(exp.startDate) : null,
            endDate: exp.endDate ? serializeDate(exp.endDate) : null,
            company: exp.company,
            description: exp.description,
            location: exp.location,
            title: exp.title,
          })),
          education: debouncedValues.education?.map((edu) => ({
            startDate: edu.startDate ? serializeDate(edu.startDate) : null,
            endDate: edu.endDate ? serializeDate(edu.endDate) : null,
            title: edu.title,
            description: edu.description,
            institution: edu.institution,
            location: edu.location,
          })),
          certifications: debouncedValues.certifications?.map((cert) => ({
            title: cert.title,
            description: cert.description,
          })),
          achievements: debouncedValues.achievements?.map((a) => ({
            description: a.description,
          })),
          languages: debouncedValues.languages?.map((l) => ({
            language: l.language,
            level: l.level,
          })),
          interests: debouncedValues.interests?.map((i) => ({
            description: i.description,
          })),
          skills: debouncedValues.skills?.map((s) => ({
            title: s.title,
            description: s.description,
          })),
        };

        await updateDocument({
          variables: {
            id: document.id,
            input: {
              candidateCvDocumentInput: {
                input: transformedValues,
                layout: debouncedValues.layout as CvInput['layout'],
              },
            },
          },
        });

        // Only update the ref if the mutation was successful
        prevValuesRef.current = debouncedValues;
      } catch (error) {
        console.error('Failed to save document:', error);
      }
    };

    if (hasChanged) {
      saveChanges();
    }
  }, [debouncedValues, updateDocument, document.id]);

  /**
   * If formValues are updated and the form has CV details, show the CV details
   */
  useEffect(() => {
    const hasLanguageLevel = !!watchedFormValues.languages?.some(({ level }) => !!level);
    // If toggles are already shown, don't hide them
    setIncludeLanguageLevel(hasLanguageLevel || includeLanguageLevel);
  }, [watchedFormValues]);

  const handleToggleLanguageLevels = (enabled: boolean) => {
    const updatedLanguages = languageFields.map((_, index) => ({
      ...formMethods.getValues(`languages.${index}`),
      level: enabled ? 3 : null,
    }));
    formMethods.setValue('languages', updatedLanguages);
    setIncludeLanguageLevel(enabled);
  };

  return (
    <div className="flex flex-1 flex-col overflow-hidden ">
      <div className="flex flex-col px-16 pt-4 ">
        <Link
          size="medium"
          LeftIcon={ArrowLeftIcon}
          onClick={() =>
            navigate(routes.candidate({ candidateId: candidateId ?? '', docType: 'CandidateCv' }))
          }
        >
          Back to Candidate
        </Link>
        <PageTitle className="pt-3" size="sm" Icon={PencilIcon} text={document.title ?? ''} />
        <p className="pt-1 text-text-medium">
          You are editing a candidate CV. Changes you make will be auto-saved.
        </p>
        <Tabs<Tabs> selected={tab} setSelected={setTab} options={TABS} />
      </div>
      <Form<CandidateCvDocumentMarkupFormValues>
        className="flex min-h-0 flex-1 flex-col gap-y-8 py-4"
        formMethods={formMethods}
      >
        {tab === 'CV Details' ? (
          <div className="flex flex-1 flex-col gap-y-6 overflow-auto px-16 pt-2 ">
            <FormError error={error} />
            <div className="grid grid-cols-2 gap-4">
              <TextField
                name="name"
                label="Name"
                placeholder="John Doe"
                ref={(el) => (formErrorRef.current['name'] = el)}
              />
              <TextField
                label="Job Title"
                name="jobTitle"
                placeholder="Senior Software Engineer"
                ref={(el) => (formErrorRef.current['jobTitle'] = el)}
              />
              <TextField
                name="location"
                label="Location"
                placeholder="Enter your location"
                ref={(el) => (formErrorRef.current['location'] = el)}
              />
              <TextField
                name="availability"
                label="Availability"
                placeholder="2 weeks"
                ref={(el) => (formErrorRef.current['availability'] = el)}
              />
              <TextField
                name="refId"
                label="Reference ID"
                placeholder="CRM-ID"
                ref={(el) => (formErrorRef.current['refId'] = el)}
              />
              <TextField
                name="rightToWork"
                label="Right to Work"
                placeholder="Citizen"
                ref={(el) => (formErrorRef.current['rightToWork'] = el)}
              />
              <TextField
                name="currentSalary"
                label="Current Salary"
                placeholder="£50,000"
                ref={(el) => (formErrorRef.current['currentSalary'] = el)}
              />
              <TextField
                name="desiredSalary"
                label="Desired Salary"
                placeholder="£60,000"
                ref={(el) => (formErrorRef.current['desiredSalary'] = el)}
              />
            </div>
            <div
              className="flex flex-col space-y-2 pt-2"
              ref={(el) => (formErrorRef.current['profileSummary'] = el)}
            >
              <RichTextField
                name="profileSummary"
                label="Profile Summary"
                placeholder="Software engineer with a passion for building scalable and efficient systems..."
                className="h-72"
              />
            </div>

            <div className="flex flex-col space-y-2">
              <h2 className="text-lg font-semibold">Work Experience</h2>
              <Reorder.Group
                values={mergedExperienceFields}
                onReorder={replaceExperiences}
                axis="y"
                className="flex flex-col gap-y-4"
              >
                {mergedExperienceFields.map((field, index) => {
                  const liveField = liveExperiences?.[index];
                  const startDateName = `experience.${index}.startDate`;
                  const endDateName = `experience.${index}.endDate`;
                  const fieldKey = `experience.${index}`;

                  return (
                    <DraggableItem
                      key={field.fieldId}
                      field={field}
                      index={index}
                      removeHandler={removeExperience}
                    >
                      <Accordion
                        headline={`${liveField?.title || 'Untitled'}${
                          liveField?.company ? ` - ${liveField.company}` : ''
                        }`}
                        subtext={formatStartEndDateSnippet(liveField.startDate, liveField.endDate)}
                        isOpen={openAccordions[fieldKey] || isAccordionOpen}
                        onToggle={() =>
                          setOpenAccordions((prev) => ({
                            ...prev,
                            [fieldKey]: !prev[fieldKey],
                          }))
                        }
                      >
                        <TextField
                          label="Job Title"
                          name={`experience.${index}.title`}
                          required
                          className="h-[42px]"
                        />
                        <TextField
                          label="Company"
                          name={`experience.${index}.company`}
                          required
                          className="h-[42px]"
                        />
                        <DatePickerField label="Start Date" name={startDateName} />
                        <DatePickerField
                          label="End Date"
                          setPlaceholderPresentIfNull={!!liveField.startDate}
                          name={endDateName}
                          startDateName={startDateName}
                        />
                        <TextField
                          label="Location"
                          name={`experience.${index}.location`}
                          className="h-[42px]"
                        />
                        <RichTextField
                          label="Description"
                          name={`experience.${index}.description`}
                          placeholder="Developed and maintained web applications using React and Node.js."
                          className="h-72"
                        />
                      </Accordion>
                    </DraggableItem>
                  );
                })}
              </Reorder.Group>
              <Link
                size="medium"
                className="text-generate-medium"
                iconClassName="text-generate-medium"
                LeftIcon={PlusIcon}
                onClick={() => {
                  const newIndex = experienceFields.length;
                  const newFieldKey = `experience.${newIndex}`;
                  appendExperience({
                    title: '',
                    company: '',
                    startDate: null,
                    endDate: null,
                    location: '',
                    description: '',
                  });
                  setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                }}
              >
                Add Experience
              </Link>
            </div>

            <div className="flex flex-col space-y-2">
              <h2 className="text-lg font-semibold">Education</h2>
              <Reorder.Group
                values={mergedEducationFields}
                onReorder={replaceEducation}
                axis="y"
                className="flex flex-col gap-y-4"
              >
                {mergedEducationFields.map((field, index) => {
                  const startDateName = `education.${index}.startDate`;
                  const endDateName = `education.${index}.endDate`;
                  const liveField = liveEducation?.[index];
                  const fieldKey = `education.${index}`;
                  const titleValue = formMethods.watch(`education.${index}.title`);
                  const institutionValue = formMethods.watch(`education.${index}.institution`);
                  const separator = titleValue && institutionValue ? ' - ' : '';

                  return (
                    <DraggableItem
                      key={field.fieldId}
                      field={field}
                      index={index}
                      removeHandler={removeEducation}
                    >
                      <Accordion
                        headline={
                          titleValue || institutionValue
                            ? `${institutionValue}${separator}${titleValue}`
                            : 'Untitled'
                        }
                        subtext={formatStartEndDateSnippet(liveField.startDate, liveField.endDate)}
                        isOpen={openAccordions[fieldKey] || isAccordionOpen}
                        onToggle={() =>
                          setOpenAccordions((prev) => ({
                            ...prev,
                            [fieldKey]: !prev[fieldKey],
                          }))
                        }
                      >
                        <TextField
                          label="Degree"
                          name={`education.${index}.title`}
                          required
                          className="h-[42px]"
                        />
                        <TextField
                          label="Institution"
                          name={`education.${index}.institution`}
                          required
                          className="h-[42px]"
                        />
                        <DatePickerField label="Start Date" name={startDateName} />
                        <DatePickerField
                          label="End Date"
                          setPlaceholderPresentIfNull={!!liveField.startDate}
                          name={endDateName}
                          startDateName={startDateName}
                        />
                        <TextField
                          label="Location"
                          name={`education.${index}.location`}
                          className="h-[42px]"
                        />
                        <RichTextField
                          label="Description"
                          name={`education.${index}.description`}
                          placeholder="Focused on software engineering and data structures."
                          className="h-72"
                        />
                      </Accordion>
                    </DraggableItem>
                  );
                })}
              </Reorder.Group>
              <Link
                size="medium"
                LeftIcon={PlusIcon}
                className="text-generate-medium"
                iconClassName="text-generate-medium"
                onClick={() => {
                  const newIndex = educationFields.length;
                  const newFieldKey = `education.${newIndex}`;
                  appendEducation({
                    title: '',
                    institution: '',
                    startDate: null,
                    endDate: null,
                    location: '',
                    description: '',
                  });
                  setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                }}
              >
                Add Education
              </Link>
            </div>

            <div className="flex flex-col space-y-2">
              <h2 className="text-lg font-semibold">Skills</h2>
              <Reorder.Group
                values={mergedSkillFields}
                onReorder={replaceSkills}
                axis="y"
                className="flex flex-col gap-y-4"
              >
                {mergedSkillFields.map((field, index) => {
                  const liveField = formMethods.watch(`skills.${index}`);
                  const fieldKey = `skills.${index}`;

                  return (
                    <DraggableItem
                      key={field.fieldId}
                      field={field}
                      index={index}
                      removeHandler={removeSkill}
                    >
                      <Accordion
                        headline={liveField?.title || 'Untitled'}
                        isOpen={openAccordions[fieldKey] || isAccordionOpen}
                        onToggle={() =>
                          setOpenAccordions((prev) => ({
                            ...prev,
                            [fieldKey]: !prev[fieldKey],
                          }))
                        }
                      >
                        <TextField
                          label="Skill"
                          name={`skills.${index}.title`}
                          required
                          className="h-[42px]"
                        />
                        <TextAreaField
                          label="Description"
                          name={`skills.${index}.description`}
                          placeholder="Proficient in modern JavaScript, including ES6+ features."
                          className="h-[105px] pt-2"
                        />
                      </Accordion>
                    </DraggableItem>
                  );
                })}
              </Reorder.Group>
              <Link
                size="medium"
                LeftIcon={PlusIcon}
                className="text-generate-medium"
                iconClassName="text-generate-medium"
                onClick={() => {
                  const newIndex = skillFields.length;
                  const newFieldKey = `skills.${newIndex}`;
                  appendSkill({
                    title: '',
                    description: '',
                  });
                  setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                }}
              >
                Add Skill
              </Link>
            </div>

            <div className="flex flex-col space-y-2">
              <h2 className="text-lg font-semibold">Achievements</h2>
              <Reorder.Group
                values={mergedAchievementFields}
                onReorder={replaceAchievements}
                axis="y"
                className="flex flex-col gap-y-4"
              >
                {mergedAchievementFields.map((field, index) => {
                  const liveField = liveAchievements?.[index];
                  const fieldKey = `achievements.${index}`;

                  return (
                    <DraggableItem
                      key={field.fieldId}
                      field={field}
                      index={index}
                      removeHandler={removeAchievement}
                    >
                      <Accordion
                        headline={
                          htmlToText(liveField?.description, {
                            baseElements: {
                              selectors: ['p'],
                            },
                          }) || 'Untitled'
                        }
                        isOpen={openAccordions[fieldKey] || isAccordionOpen}
                        onToggle={() =>
                          setOpenAccordions((prev) => ({
                            ...prev,
                            [fieldKey]: !prev[fieldKey],
                          }))
                        }
                      >
                        <RichTextField
                          label="Description"
                          name={`achievements.${index}.description`}
                          placeholder="Awarded Employee of the Year for outstanding performance in 2022."
                          className="h-64"
                          required
                        />
                      </Accordion>
                    </DraggableItem>
                  );
                })}
              </Reorder.Group>
              <Link
                size="medium"
                LeftIcon={PlusIcon}
                className="text-generate-medium"
                iconClassName="text-generate-medium"
                onClick={() => {
                  const newIndex = achievementFields.length;
                  const newFieldKey = `achievements.${newIndex}`;
                  appendAchievement({
                    description: '',
                  });
                  setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                }}
              >
                Add Achievement
              </Link>
            </div>

            <div className="flex flex-col space-y-2">
              <h2 className="text-lg font-semibold">Certifications</h2>
              <Reorder.Group
                values={mergedCertificationFields}
                onReorder={replaceCertifications}
                axis="y"
                className="flex flex-col gap-y-4"
              >
                {mergedCertificationFields.map((field, index) => {
                  const fieldKey = `certifications.${index}`;

                  return (
                    <DraggableItem
                      key={field.fieldId}
                      field={field}
                      index={index}
                      removeHandler={removeCertification}
                    >
                      <Accordion
                        headline={field.title || 'Untitled'}
                        isOpen={openAccordions[fieldKey] || isAccordionOpen}
                        onToggle={() =>
                          setOpenAccordions((prev) => ({
                            ...prev,
                            [fieldKey]: !prev[fieldKey],
                          }))
                        }
                      >
                        <TextField
                          label="Certification Title"
                          name={`certifications.${index}.title`}
                          required
                          className="h-[42px]"
                        />
                        <RichTextField
                          label="Description"
                          name={`certifications.${index}.description`}
                          placeholder="Certification demonstrating advanced knowledge of JavaScript."
                          className="h-72"
                        />
                      </Accordion>
                    </DraggableItem>
                  );
                })}
              </Reorder.Group>
              <Link
                size="medium"
                LeftIcon={PlusIcon}
                className="text-generate-medium"
                iconClassName="text-generate-medium"
                onClick={() => {
                  const newIndex = certificationFields.length;
                  const newFieldKey = `certifications.${newIndex}`;
                  appendCertification({
                    title: '',
                    description: '',
                  });
                  setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                }}
              >
                Add Certification
              </Link>
            </div>

            <div className="flex flex-col space-y-2">
              <div className="flex items-center justify-between">
                <h2 className="text-lg font-semibold">Languages</h2>
                {liveLanguages?.length > 0 && (
                  <div className="flex items-center justify-center gap-3">
                    <p className="text-sm font-normal text-text-medium">Include level</p>
                    <Toggle enabled={includeLanguageLevel} onChange={handleToggleLanguageLevels} />
                  </div>
                )}
              </div>
              <Reorder.Group
                values={mergedLanguageFields}
                onReorder={replaceLanguages}
                axis="y"
                className="flex flex-col gap-y-4"
              >
                {mergedLanguageFields.map((field, index) => {
                  const liveField = liveLanguages?.[index];
                  const fieldKey = `languages.${index}`;

                  return (
                    <DraggableItem
                      key={field.fieldId}
                      field={field}
                      index={index}
                      removeHandler={removeLanguage}
                    >
                      <Accordion
                        headline={liveField?.language || 'Untitled'}
                        subtext={
                          liveField?.level !== null
                            ? getLanguageLevelLabel(liveField?.level ?? 1)
                            : ''
                        }
                        isOpen={openAccordions[fieldKey] || isAccordionOpen}
                        onToggle={() =>
                          setOpenAccordions((prev) => ({
                            ...prev,
                            [fieldKey]: !prev[fieldKey],
                          }))
                        }
                      >
                        <TextField
                          label="Language"
                          name={`languages.${index}.language`}
                          required
                          className="h-[42px]"
                        />
                        {includeLanguageLevel && (
                          <SelectField
                            label="Proficiency Level"
                            name={`languages.${index}.level`}
                            options={[
                              { value: 1, label: 'Novice' },
                              { value: 2, label: 'Intermediate' },
                              { value: 3, label: 'Skilled' },
                              { value: 4, label: 'Advanced' },
                              { value: 5, label: 'Expert' },
                            ]}
                          />
                        )}
                      </Accordion>
                    </DraggableItem>
                  );
                })}
              </Reorder.Group>
              <Link
                size="medium"
                LeftIcon={PlusIcon}
                className="text-generate-medium"
                iconClassName="text-generate-medium"
                onClick={() => {
                  const newIndex = languageFields.length;
                  const newFieldKey = `languages.${newIndex}`;
                  appendLanguage({
                    language: '',
                    level: null,
                  });
                  setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                }}
              >
                Add Language
              </Link>
            </div>

            <div className="flex flex-col space-y-2">
              <h2 className="text-lg font-semibold ">Interests</h2>
              <Reorder.Group
                values={mergedInterestFields}
                onReorder={replaceInterests}
                axis="y"
                className="flex flex-col gap-y-4"
              >
                {mergedInterestFields.map((field, index) => {
                  const liveField = liveInterests?.[index];
                  const fieldKey = `interests.${index}`;

                  return (
                    <DraggableItem
                      key={field.fieldId}
                      field={field}
                      index={index}
                      removeHandler={removeInterest}
                    >
                      <Accordion
                        headline={liveField?.description || 'Untitled'}
                        isOpen={openAccordions[fieldKey] || isAccordionOpen}
                        onToggle={() =>
                          setOpenAccordions((prev) => ({
                            ...prev,
                            [fieldKey]: !prev[fieldKey],
                          }))
                        }
                      >
                        <TextAreaField
                          label="Interest"
                          name={`interests.${index}.description`}
                          placeholder="Hiking, photography, and open-source contributions."
                          className="min-h-24 flex-grow"
                          required
                        />
                      </Accordion>
                    </DraggableItem>
                  );
                })}
              </Reorder.Group>
              <Link
                size="medium"
                LeftIcon={PlusIcon}
                className="text-generate-medium"
                iconClassName="text-generate-medium"
                onClick={() => {
                  const newIndex = interestFields.length;
                  const newFieldKey = `interests.${newIndex}`;
                  appendInterest({
                    description: '',
                  });
                  setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                }}
              >
                Add Interest
              </Link>
            </div>
          </div>
        ) : (
          <div className="flex flex-1 flex-col gap-y-6 overflow-auto px-16 pt-2 ">
            <div className="pb-4">
              <h2 className="pb-1 text-base font-semibold text-text-dark">Layout</h2>
              <p className="text-sm text-text-medium">
                Drag the cards to re-arrange sections in your CV.
              </p>
            </div>

            <DocumentLayoutEditorField name="layout" documentType="CandidateCv" />
          </div>
        )}
      </Form>
    </div>
  );
};
