export const GET_LATEST_RELEASE_NOTIFIER_QUERY = gql`
  query GetLatestReleaseNotifierQuery {
    latestReleaseNotifier {
      id
      version
      title
      description
      releaseNotesUrl
    }
  }
`;
