export const COVERSHEET_LAYOUT_FIELDS_FRAGMENT = gql`
  fragment CoversheetLayoutField on CandidateCoversheetLayoutField {
    row
    column
    fixed
    hidden
  }

  fragment CoversheetLayoutFields on CandidateCoversheetLayout {
    name {
      ...CoversheetLayoutField
    }
    jobTitle {
      ...CoversheetLayoutField
    }
    profileIntroduction {
      ...CoversheetLayoutField
    }
    desiredJobTitle {
      ...CoversheetLayoutField
    }
    desiredSalary {
      ...CoversheetLayoutField
    }
    sectors {
      ...CoversheetLayoutField
    }
    noticePeriod {
      ...CoversheetLayoutField
    }
    location {
      ...CoversheetLayoutField
    }
    refId {
      ...CoversheetLayoutField
    }
    consultantDetails {
      ...CoversheetLayoutField
    }
    relevantSkills {
      ...CoversheetLayoutField
    }
    qualifications {
      ...CoversheetLayoutField
    }
    rightToWork {
      ...CoversheetLayoutField
    }
  }
`;
