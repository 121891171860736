import { COVERSHEET_LAYOUT_FIELDS_FRAGMENT } from 'src/graphql/fragments';
export const UPDATE_COVERSHEET_CONFIG_MUTATION = gql`
  ${COVERSHEET_LAYOUT_FIELDS_FRAGMENT}
  mutation UpdateCoversheetConfig($input: UpdateCoversheetConfigInput!) {
    updateCoversheetConfig(input: $input) {
      id
      coversheetTemplate {
        id
        defaultLayout {
          ...CoversheetLayoutFields
        }
      }
      accentColor
      headerType
      logoAlignment
      font
      fontColor
      capitaliseHeading
      boldHeading
      showBorder
      showFooter
      borderColor
      marginSize
      headingFont
      headingColor
      fontScale
      defaultLayout {
        ...CoversheetLayoutFields
      }
    }
  }
`;
