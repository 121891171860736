import React, { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { CSS } from '@dnd-kit/utilities';
import {
  closestCenter,
  pointerWithin,
  rectIntersection,
  CollisionDetection,
  DndContext,
  getFirstCollision,
  MouseSensor,
  UniqueIdentifier,
  useSensors,
  useSensor,
  MeasuringStrategy,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  SortableContext,
  useSortable,
  arrayMove,
  defaultAnimateLayoutChanges,
} from '@dnd-kit/sortable';
import { classNames } from 'src/lib';
import { IconButton } from 'src/components/IconButton';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import GrabIcon from 'src/assets/GrabIcon';
import { COVERSHEET_FIELD_PRESENTATIONAL_NAMES, CV_FIELD_PRESENTATIONAL_NAMES } from './util';
import { LockClosedIcon } from '@heroicons/react/24/solid';

type Layout = {
  [key: string]: {
    column: number;
    row: number;
    fixed: boolean;
    hidden?: boolean;
  };
};

type DocumentLayoutEditorProps = {
  value: Layout;
  documentType: 'CandidateCv' | 'CandidateCoversheet';
  onChange: (value: Layout) => void;
};

export const DocumentLayoutEditor: FC<DocumentLayoutEditorProps> = ({
  value,
  onChange,
  documentType,
}) => {
  const { columns, hidden, fixed } = parseLayout(value);
  const [activeId, setActiveId] = useState<string | number | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const key =
    documentType === 'CandidateCv'
      ? CV_FIELD_PRESENTATIONAL_NAMES
      : COVERSHEET_FIELD_PRESENTATIONAL_NAMES;

  const [clonedItems, setClonedItems] = useState<string[][] | null>(null);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const containers: Array<string | number> = columns?.map((_, index) => `${index}`);
  const isSortingContainer = activeId != null ? containers.includes(activeId) : false;

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId in columns.map((_, i) => `${i}`)) {
          const containerItems = columns[parseInt(`${overId}`)];

          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, columns]
  );

  const findContainer = (id: string) => {
    const intId = parseInt(id);

    if (Number.isFinite(intId)) {
      return id;
    }

    const containerIndex = columns.findIndex((_, i) => columns[i].includes(id));

    if (containerIndex === -1) {
      return null;
    }

    return `${containerIndex}`;
  };

  const getIndex = (id: string) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = columns[parseInt(container)].indexOf(id);

    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      onRearrange(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [columns]);

  const onRearrange = (items: string[][]) => {
    // Convert columns array back to Layout type
    const layout: Layout = {
      ...value,
    };

    // Add items from columns with their positions
    items.forEach((column, columnIndex) => {
      column.forEach((item, rowIndex) => {
        layout[item] = {
          column: columnIndex + 1,
          row: rowIndex + 1,
          fixed: fixed.includes(item),
          hidden: hidden.includes(item),
        };
      });
    });

    // Add any remaining hidden items to maintain complete state
    hidden.forEach((item) => {
      layout[item] = {
        ...value[item],
        hidden: true,
      };
    });

    onChange(layout);
  };

  const onHideItem = (item: string) => {
    if (fixed.includes(item)) {
      return;
    }

    const newValue = {
      ...value,
      [item]: {
        ...value[item],
        hidden: true,
      },
    };

    onChange(newValue);
  };

  const onRestoreItem = (item: string) => {
    const newValue = {
      ...value,
      [item]: {
        ...value[item],
        hidden: false,
      },
    };

    onChange(newValue);
  };

  return (
    <div className="flex flex-col">
      <DndContext
        sensors={sensors}
        collisionDetection={collisionDetectionStrategy}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        onDragStart={({ active }) => {
          setActiveId(active.id);
          setClonedItems(columns);
        }}
        onDragOver={({ active, over }) => {
          const overId = over?.id as string | undefined;

          if (overId == null || active.id in columns) {
            return;
          }

          const overContainer = findContainer(overId);
          const activeContainer = findContainer(active.id as string);

          if (!overContainer || !activeContainer) {
            return;
          }

          if (activeContainer !== overContainer) {
            const activeItems = columns[parseInt(activeContainer)];
            const overItems = columns[parseInt(overContainer)];
            const overIndex = overItems.indexOf(overId);
            const activeIndex = activeItems.indexOf(active.id as string);

            let newIndex: number;

            if (overId in columns) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top > over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            recentlyMovedToNewContainer.current = true;

            const rearrangedItems = columns.map((item, index) => {
              if (index === parseInt(activeContainer)) {
                return item.filter((item) => item !== active.id);
              }

              if (index === parseInt(overContainer)) {
                return [
                  ...item.slice(0, newIndex),
                  columns[parseInt(activeContainer)][activeIndex],
                  ...item.slice(newIndex, item.length),
                ];
              }

              return item;
            });

            onRearrange(rearrangedItems);
          }
        }}
        onDragEnd={({ active, over }) => {
          const activeContainer = findContainer(active.id as string);

          if (!activeContainer) {
            setActiveId(null);
            return;
          }

          const overId = over?.id as string | undefined;

          if (overId == null) {
            setActiveId(null);
            return;
          }

          const overContainer = findContainer(overId);

          if (overContainer) {
            const activeIndex = columns[parseInt(activeContainer)].indexOf(active.id as string);
            const overIndex = columns[parseInt(overContainer)].indexOf(overId);

            if (activeIndex !== overIndex) {
              const rearrangedItems = columns.map((item, index) => {
                if (index === parseInt(overContainer)) {
                  return arrayMove(item, activeIndex, overIndex);
                }

                return item;
              });

              onRearrange(rearrangedItems);
            }
          }

          setActiveId(null);
        }}
        onDragCancel={onDragCancel}
      >
        <div className="flex flex-row gap-x-6">
          <SortableContext items={containers}>
            {containers.map((_, containerIndex) => (
              <div key={containerIndex}>
                <h4 className="pb-2 text-sm font-semibold text-text-dark">{`Column ${
                  containerIndex + 1
                }`}</h4>

                <DroppableContainer id={`${containerIndex}`} items={columns[containerIndex]}>
                  <SortableContext items={columns[containerIndex]}>
                    {columns[containerIndex].map((value, index) => {
                      const isFixed = fixed.includes(value);
                      return isFixed ? (
                        <ItemComponent
                          fixed={fixed.includes(value)}
                          // @ts-expect-error - key is a string indexer
                          label={key[value]}
                          onHideItem={() => onHideItem(value)}
                        />
                      ) : (
                        <SortableItem
                          disabled={isSortingContainer}
                          key={value}
                          id={value}
                          index={index}
                          containerId={`${containerIndex}`}
                          getIndex={getIndex}
                        >
                          <ItemComponent
                            fixed={fixed.includes(value)}
                            // @ts-expect-error - key is a string indexer
                            label={key[value]}
                            onHideItem={() => onHideItem(value)}
                          />
                        </SortableItem>
                      );
                    })}
                  </SortableContext>
                </DroppableContainer>
              </div>
            ))}
          </SortableContext>
        </div>
      </DndContext>

      <div className="pt-6">
        <h2 className="pb-1 text-base font-semibold text-text-dark">Hidden</h2>
        <p className="text-sm text-text-medium">
          These sections will be hidden. Click the eye icon to include them in the document.
        </p>
        <div className="flex flex-col gap-y-2 pt-4">
          {hidden.map((item) => (
            <div
              className="flex w-52 flex-row items-center justify-between border border-text-light px-3 py-2 text-sm font-medium text-text-medium"
              key={item}
            >
              {/* @ts-expect-error - key is a string indexer */}
              <div>{key[item]}</div>
              <IconButton
                Icon={EyeSlashIcon}
                size="small"
                tooltipText="Show"
                onClick={() => onRestoreItem(item)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

type SortableItemProps = {
  containerId: UniqueIdentifier;
  id: UniqueIdentifier;
  index: number;
  disabled?: boolean;
  getIndex(id: UniqueIdentifier): number;
};

const SortableItem: React.FC<PropsWithChildren<SortableItemProps>> = ({
  disabled,
  id,
  children,
}) => {
  const { setNodeRef, setActivatorNodeRef, listeners, transform, transition, isDragging } =
    useSortable({
      id,
    });

  return (
    <ul
      style={{
        transform: CSS.Translate.toString(transform),
        transition: transition,
      }}
      {...listeners}
      ref={disabled ? undefined : setNodeRef}
      className={classNames('relative', isDragging && 'z-50')}
    >
      <div className=" absolute -left-5 top-2 hidden cursor-pointer text-text-light hover:text-text-medium group-hover:block">
        <GrabIcon className="h-5 w-5" />
      </div>
      {children}
    </ul>
  );
};

function parseLayout(layout: Layout): {
  columns: Array<Array<string>>;
  hidden: Array<string>;
  fixed: Array<string>;
} {
  const sections = Object.keys(layout);
  const hidden = sections.filter((key) => layout[key]?.hidden);
  const fixed = sections.filter((key) => layout[key]?.fixed);

  const mapped = sections.filter((key) => !hidden.includes(key));

  const nColumns = Math.max(...sections.map((item) => layout[item]?.column ?? 1));

  // Group items into relevant columns
  const columns = Array.from({ length: nColumns }, (_, index) => {
    return mapped
      .filter((item) => (layout[item]?.column ?? 1) === index + 1)
      .sort((a, b) => layout[a]?.row - layout[b]?.row);
  });

  return { columns, hidden, fixed };
}

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

type ContainerProps = {
  children: React.ReactNode;
  columns?: number;
  label?: string;
  style?: React.CSSProperties;
  horizontal?: boolean;
  hover?: boolean;
  handleProps?: React.HTMLAttributes<any>;
  scrollable?: boolean;
  shadow?: boolean;
  unstyled?: boolean;
  onClick?(): void;
  onRemove?(): void;
};

const DroppableContainer: FC<
  PropsWithChildren<
    ContainerProps & {
      disabled?: boolean;
      id: UniqueIdentifier;
      items: UniqueIdentifier[];
      style?: React.CSSProperties;
    }
  >
> = ({ children, disabled, id, items, ...props }) => {
  const { active, over, setNodeRef, transition, transform } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  });

  return (
    <ul
      className={classNames(
        'group relative flex min-h-56 min-w-52 flex-col gap-y-2',
        !!active && 'border border-dashed border-text-light bg-generate-light'
      )}
      ref={disabled ? undefined : setNodeRef}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
      {...props}
    >
      {children}
    </ul>
  );
};

const ItemComponent: FC<{
  label: string;
  onHideItem: () => void;
  fixed: boolean;
}> = ({ label, onHideItem, fixed }) => {
  return (
    <div
      className={classNames(
        'flex min-w-32 flex-row items-center justify-between border border-text-light bg-pageGray px-3 py-1.5 text-sm font-medium text-text-medium',
        !fixed && 'cursor-pointer'
      )}
    >
      {label}
      {fixed && (
        <IconButton
          disabled
          onClick={onHideItem}
          tooltipText="This section is locked"
          Icon={LockClosedIcon}
          size="small"
        />
      )}
      {!fixed && (
        <IconButton
          onClick={onHideItem}
          tooltipText="Hide"
          Icon={EyeIcon}
          size="small"
          variant="light"
        />
      )}
    </div>
  );
};
